import type { SwitchHeatCabinet } from './SwitchHeatCabinet'
import type { SwitchObject } from './SwitchObject'
import type { Bender } from './Bender'
import type { Rack } from './Rack'
import type { NewTrackCircuit } from './NewTrackCircuit'
import type { SystemStatus } from './SystemStatus'
import type { AssetStatusCause } from 'src/Types/AssetStatusEntry'

/**
 * AssetType having mapping to View / System \ CriticalityComponentTypeId as follows:
 * SwitchPointMachine - Switches and heat / Switches \ Switch (1)
 * SwitchMachineHeat - Switches and heat / Switch (machine heat) \ MachineHeat (5)
 * TrackCircuit - Track circuit \ TrackCircuit (2)
 * SwitchHeat - Switches and heat / Switch heat cabinet \ SwitchHeat (3)
 * Bender - Signal earth faults / Benders \ SignalEarthFault (4)
 * Rack - Signal earth faults / Racks \ SignalEarthFault (4)
 * DerailerMachine - Switches and heat / Derailer \ Switch (1)
 */
export enum AssetType {
  SwitchPointMachine = 'switchPointMachine',
  SwitchMachineHeat = 'switchMachineHeat',
  TrackCircuit = 'trackCircuit',
  SwitchHeat = 'switchHeat',
  Bender = 'bender',
  Rack = 'rack',
  DerailerMachine = 'derailerMachine',
  Unknown = 'unknown',
}

export type AssetStatusWindowStatus = 'Out_of_Order' | 'Maintenance'

export type AssetStatusWindow = {
  baneDataId: string
  status: AssetStatusWindowStatus
  startTime: number
  endTime: number
  causeId: number
  cause: AssetStatusCause
}

export type AssetFields = {
  assetType: AssetType
  baneDataId: string
  name: string
  baneDataLocationName: string
  baneDataLocationId: string
  fromKm?: number
  toKm?: number
  isMonitored: boolean
  componentCriticalityLocal: number
  alarmCriticalityAbsolute: number
  totalCriticalityAbsolute: number
  assetStatusWindows?: AssetStatusWindow[]
  statusWithAssetStatusWindows: SystemStatus
}

export type Asset = NewTrackCircuit | SwitchObject | SwitchHeatCabinet | Bender | Rack
