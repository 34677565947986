import { darken, alpha, lighten } from '@mui/material/styles'

const PRIMARY_COLOR = '#1E285A'
const SECONDARY_COLOR = '#3C32C8'
const TERTIARY_COLOR = '#2D2D87'

const BLACK = '#000000'
const ERROR = '#FF3B3B'
const YELLOW = '#FFD200'
const BLUE = '#5CC9FF'
const GREEN = '#64C82D'

const colors = {
  primary: PRIMARY_COLOR,
  secondary: SECONDARY_COLOR,
  tertiary: TERTIARY_COLOR,
  body: '#F4F4F6',
  text: TERTIARY_COLOR,
  linkText: SECONDARY_COLOR,
  // darkPrimary: '#151C3E', // not in use
  error: ERROR,
  errorText: '#DD0000',
  errorBackground: lighten(ERROR, 0.8),
  warning: YELLOW,
  warningText: '#D2AD00',
  warningBackground: lighten(YELLOW, 0.8),
  outOfOrder: '#D1D1D1',
  info: BLUE,
  infoBackground: lighten(BLUE, 0.8),
  success: GREEN,
  successText: '#3A7E15',
  successBackground: lighten(GREEN, 0.8),
  disabled: '#DDDDDD',
  disabledText: alpha(BLACK, 0.55),
  disabledLight: '#D1D1D1',
  maintenance: TERTIARY_COLOR,
  maintenanceText: 'white',
  orange: '#FA9664',
  sectionHeader: alpha(PRIMARY_COLOR, 0.18),
  sectionHeaderHover: alpha(PRIMARY_COLOR, 0.28),
  sectionSubHeader: alpha(PRIMARY_COLOR, 0.1),
  sectionSubHeaderHover: alpha(PRIMARY_COLOR, 0.2),
  sectionBackground: alpha(PRIMARY_COLOR, 0.1),
  sectionAlternateRow: alpha(PRIMARY_COLOR, 0.1),
  tableCollapseBackground: alpha(PRIMARY_COLOR, 0.05),
  tableHeadHover: alpha(PRIMARY_COLOR, 0.7),
  hr: '#808080',
  sheet: '#FFF',
  weather: {
    sun: YELLOW,
    rain: '#00AAFF',
    plusDegrees: '#ff6666',
    plusDegreesToday: '#DD0000',
    minusDegrees: '#00AAFF',
    minusDegreesToday: '#006395',
  },
  fontSize: '0.875rem',
}

// The non-Bane NOR colors are taken from "Kelly’s 22 Colours of Maximum Contrast"
const lineColors = [
  colors.weather.rain,
  colors.success,
  colors.warning,
  '#BA1C30',
  '#702C8C',
  '#7F7E80',
  '#DB6917',
  '#463397',
  '#D485B2',
  '#EBCE2B',
  colors.primary,
  '#6F340D',
  '#C0BD7F',
  '#2B3514',
  '#4277B6',
  '#DF8461',
  '#E1A11A',
  '#91218C',
  '#E8E948',
  '#7E1510',
  '#92AE31',
  '#D32B1E',
  '#1D1D1D',
  '#96CDE6',
  '#5FA641',
]

export default {
  ...colors,
  chartColors: {
    lines: lineColors,
    bands: lineColors,
    referenceCurve: ['#ccc', '#aaa', '#aaa'],
    alarms: {
      error: alpha(colors.error, 0.4),
      warning: alpha(colors.warning, 0.8),
    },
    alarmText: {
      error: darken(colors.error, 0.15),
      warning: darken(colors.warning, 0.15),
    },
    weatherFocus: colors.sectionHeader,
    wind: '#5FA641',
    padding: alpha(colors.disabledLight, 0.6),
    subordinate: '#ccc',
  },
}
