import type { SystemStatus } from 'src/Types/SystemStatus'
import type { AlarmClassification } from 'src/Types/AlarmClassification'

const allAlarmClassifications = ['Alarm', 'Warning']

/**
 * Filters the alarm (see filterAlarm.ts) based on system statuses of the filter
 * (being FilterType.Status) and the alarmClassification of the alarm.
 * @param filteredStatuses The system statuses of the FilterType.Status filter
 * @param alarmClassification The alarmClassification of the alarm (or undefined if n/a)
 * @param statusForAsset The system status (a.k.a. asset status) that
 * goes hand in hand with the asset status windows (seven day indicators)
 * (a.k.a. statusWithAssetStatusWindows)
 */
export const filterAlarmsByStatus = (
  filteredStatuses: SystemStatus[],
  statusForAsset: SystemStatus,
  alarmClassification?: AlarmClassification
) => {
  if (!alarmClassification) {
    return false
  }

  // The asset status is among selected ones to be filtered on
  const matchStatusForAsset = filteredStatuses.includes(statusForAsset)

  /* If the status filter has a (selected) status that is not an alarmClassification,
   * such as 'Out of Order' etc. (e.g. [Alarm, Out_of_Order] being the filter) */
  if (filteredStatuses.some(status => !allAlarmClassifications.includes(status))) {
    // then we require that the status is among the selected ones
    return matchStatusForAsset
  }

  const matchAlarmClassification = filteredStatuses.includes(alarmClassification)

  // If the asset status is neither 'Alarm' nor 'Warning' (like 'Out_of_order' for example),
  if (!allAlarmClassifications.includes(statusForAsset)) {
    /* then we require BOTH a match in filteredStatuses
     * (meaning that 'Out_of_order' is selected in the systems dropdown,
     * in the above example case that also 'Out_of_order' is the asset status)
     * and that the alarmClassification is defined (being either 'Alarm' or 'Warning')
     * and among selected statuses for the filter. */
    return matchStatusForAsset && matchAlarmClassification
  }

  /* Or when the asset status is either 'Alarm' or 'Warning',
   * we then require EITHER matching asset status or alarm classification */
  return matchStatusForAsset || matchAlarmClassification
}
