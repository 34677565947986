import { callApiGet } from 'src/msGraphClient'
import { ApiType } from 'src/Types/ApiType'
import config from 'src/Config/Config'
import type { AssetStatusEntryCause } from 'src/Types/AssetStatusEntry'

const { ASSET_STATUS_CAUSE } = config.API_FRAGMENTS.apim

type AssetStatusEntryCauseContract = {
  causeId: number
  name_No: string
  name_En: string
}

export const getAssetStatusEntryCauses = async () => {
  const result = await callApiGet<AssetStatusEntryCauseContract[]>(ASSET_STATUS_CAUSE, ApiType.APIM)
  /* eslint-disable camelcase */
  return result.map<AssetStatusEntryCause>(({ causeId, name_No, name_En }) => ({
    id: causeId,
    name: {
      no: name_No,
      en: name_En,
    },
  }))
  /* eslint-enable camelcase */
}
