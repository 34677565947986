import type { ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

const StyledGridText = styled(Grid)(
  props => `
  font-size: 14px;
  text-align: center;
  display: block;
  ${props.theme.breakpoints.down('lg')} {
    font-size: 12px;
  }
`
)

type OwnProps = {
  icon: ReactNode
  text: string | ReactNode
  isUpperCase?: boolean
  isActive?: boolean
  gap?: number
}

const styledText = (text: string, isActive: boolean) => {
  return isActive ? <b>{text}</b> : text
}

export const IconWithText = ({ icon, text, isUpperCase = false, isActive = false, gap = 0 }: OwnProps) => (
  <Grid container spacing={0} direction="column" justifyContent="center" alignItems="center" gap={gap}>
    <Grid item xs={12}>
      {icon}
    </Grid>
    <StyledGridText item xs={12}>
      {typeof text === 'string' ? styledText(isUpperCase ? text.toUpperCase() : text, isActive) : text}
    </StyledGridText>
  </Grid>
)
