import type { Filter } from 'src/Types/Filter'
import { FilterType } from 'src/Types/Filter'
import type { SystemStatus } from 'src/Types/SystemStatus'

/**
 * Sets default selected system and status filter
 *
 * @param selectedStatus - Selected status to set
 * @param selectedSystem - Selected system to set
 */
export const setDefaultSelectedFilters = (selectedStatus: SystemStatus[], selectedSystem: string[]): Filter[] => {
  const filters: Filter[] = []
  if (selectedStatus.length > 0) {
    filters.push({
      type: FilterType.Status,
      values: selectedStatus,
    })
  }
  if (selectedSystem.length > 0) {
    filters.push({
      type: FilterType.System,
      values: selectedSystem,
    })
  }

  return filters
}
