import type { ChangeEventHandler } from 'react'
import { useState, useEffect, useMemo } from 'react'
import { TextField, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Button } from 'src/Components/Button'
import { ManualAssetStatusCauseSelector } from 'src/Features/ManualAssetStatus/ManualAssetStatusCauseSelector'
import type { ManualAssetStatusCause, ChangeManualAssetStatusEvent } from 'src/Features/ManualAssetStatus/types'
import { ChangeManualAssetStatusEventType } from 'src/Features/ManualAssetStatus/types'
import { useLanguage } from 'src/Hooks/useLanguage'
import theme from 'src/theme'

export enum ManualAssetStatusFormMode {
  EnterOutOfOrder,
  ExitOutOfOrder,
}

export type ManualAssetStatusFormProps = {
  availableCauses: ManualAssetStatusCause[]
  onSubmit: (event: ChangeManualAssetStatusEvent) => void
  onCancel: () => void
  formMode: ManualAssetStatusFormMode | undefined
  formDisabled: boolean
}

const StyledFormWarning = styled(Typography)`
  color: ${theme.errorText};
  font-size: 14px;
`

const StyledTextField = styled(TextField)`
  margin: 0.75rem 0;

  .MuiInputBase-root {
    border-radius: inherit;
    border: none;
  }

  .MuiOutlinedInput-notchedOutline {
    border: solid 1px ${theme.hr};

    legend {
      text-shadow: 2px 2px #fff;
    }
  }
`

export const ManualAssetStatusForm = ({
  availableCauses,
  onSubmit,
  onCancel,
  formMode,
  formDisabled,
}: ManualAssetStatusFormProps) => {
  const { t } = useLanguage()
  const [selectedCause, setSelectedCause] = useState<number>()
  const [endTime, setEndTime] = useState<Date | undefined>()
  const [comment, setComment] = useState<string | undefined>('')
  const [validated, setValidated] = useState(false)

  const isValidEndTime = useMemo(() => !endTime || endTime > new Date(), [endTime])
  const isValidComment = useMemo(() => !!comment?.length, [comment])
  const isValidCause = useMemo(() => typeof selectedCause === 'number', [selectedCause])

  const warnings = useMemo(() => {
    const warnings: string[] = []
    if (!isValidComment) {
      warnings.push(t('manualAssetStatus.missingCommentMessage'))
    }
    if (formMode === ManualAssetStatusFormMode.EnterOutOfOrder) {
      if (!isValidCause) {
        warnings.push(t('manualAssetStatus.missingCauseMessage'))
      }
      if (!isValidEndTime) {
        warnings.push(t('manualAssetStatus.invalidEndTimeMessage'))
      }
    }
    return warnings
  }, [isValidCause, isValidComment, isValidEndTime, formMode, t])

  const isValidForm = !warnings.length

  useEffect(() => {
    setEndTime(undefined)
    setSelectedCause(undefined)
    setComment('')
    setValidated(false)
  }, [formMode, formDisabled])

  const handleSubmit = () => {
    if (!isValidForm || !comment) {
      setValidated(true)
      return
    }
    if (formMode === ManualAssetStatusFormMode.ExitOutOfOrder) {
      onSubmit({
        type: ChangeManualAssetStatusEventType.Exit,
        comment,
      })
    }
    if (formMode === ManualAssetStatusFormMode.EnterOutOfOrder) {
      onSubmit({
        type: ChangeManualAssetStatusEventType.Enter,
        comment,
        cause: selectedCause!,
        endTime: endTime?.getTime(),
      })
    }
  }

  const handleCauseChange = (cause: number | undefined) => {
    setSelectedCause(cause)
  }

  const handleCommentChange: ChangeEventHandler<HTMLInputElement> = e => {
    setComment(e.target.value)
  }

  return (
    <Grid container direction="column" gap={1}>
      {formMode === ManualAssetStatusFormMode.EnterOutOfOrder && (
        <Grid item xs={6}>
          <ManualAssetStatusCauseSelector
            availableCauses={availableCauses}
            selectedCause={selectedCause}
            onChange={handleCauseChange}
            disabled={formDisabled}
          />
        </Grid>
      )}
      {validated && !!warnings.length ? (
        <Grid item>
          {warnings.map(warning => (
            <StyledFormWarning key={warning}>{warning}</StyledFormWarning>
          ))}
        </Grid>
      ) : undefined}
      <Grid item>
        <StyledTextField
          fullWidth
          label={t('manualAssetStatus.comment')}
          multiline
          rows={3}
          variant="outlined"
          value={comment}
          onChange={handleCommentChange}
          disabled={formDisabled}
        />
      </Grid>
      <Grid container direction="row" gap={1}>
        <Button onClick={handleSubmit} disabled={formDisabled}>
          {formMode === ManualAssetStatusFormMode.EnterOutOfOrder
            ? t('manualAssetStatus.setOutOfOrderButton')
            : t('manualAssetStatus.setInNormalOperationButton')}
        </Button>
        <Button variant="outlined" onClick={onCancel} disabled={formDisabled}>
          {t('general.cancel')}
        </Button>
      </Grid>
    </Grid>
  )
}
