import { SortOrder } from 'src/Types/SortOrder'
import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import type { OpenedAlarm } from 'src/Types/OpenedAlarm'

import { getLatestAlarmTime } from 'src/Utils/alarms'

const IGNORED_ASSET_STATUS_ORDER = ['Out_of_Order', 'Maintenance', 'NotMonitored']

const sortByName = (a: AssetWithAlarms, b: AssetWithAlarms) => a.asset.name.localeCompare(b.asset.name)

const sortByLatestAlarmDate = (a: AssetWithAlarms, b: AssetWithAlarms) => {
  const latestA = getLatestAlarmTime(a.alarms)
  const latestB = getLatestAlarmTime(b.alarms)
  if (latestA !== undefined && latestB !== undefined) {
    return latestB - latestA
  }
  if (latestA === undefined && latestB === undefined) {
    return 0
  }
  return latestA === undefined ? 1 : -1
}

const sortByCriticality = (a: AssetWithAlarms, b: AssetWithAlarms) => {
  if (a.asset.showCriticality && b.asset.showCriticality) {
    return b.asset.totalCriticalityAbsolute - a.asset.totalCriticalityAbsolute
  }
  return sortByLatestAlarmDate(a, b) || sortIgnoredAssetStatusToBottom(b, a)
}

const sortByKilometer = (a: AssetWithAlarms, b: AssetWithAlarms) => {
  if (a.asset.fromKm && b.asset.fromKm) {
    return a.asset.fromKm - b.asset.fromKm
  }
  return a.asset.baneDataLocationName.localeCompare(b.asset.baneDataLocationName) || sortByName(a, b)
}

const sortByUnopenedAssetCard = (a: AssetWithAlarms, b: AssetWithAlarms, openedAlarms: OpenedAlarm[]) => {
  const openedA = openedAlarms.some(oa => oa.baneDataId === a.asset.baneDataId)
  const openedB = openedAlarms.some(oa => oa.baneDataId === b.asset.baneDataId)

  return openedA !== openedB ? +openedA - +openedB : sortByLatestAlarmDate(a, b)
}

const sortIgnoredAssetStatusToBottom = (a: AssetWithAlarms, b: AssetWithAlarms) =>
  IGNORED_ASSET_STATUS_ORDER.indexOf(b.asset?.statusWithAssetStatusWindows || '') -
  IGNORED_ASSET_STATUS_ORDER.indexOf(a.asset?.statusWithAssetStatusWindows || '')

export const sortAssetsWithAlarms = (assetsWithAlarms: AssetWithAlarms[], sortOrder: SortOrder, openedAlarms: OpenedAlarm[]) =>
  assetsWithAlarms.sort((a, b) => {
    if (sortOrder === SortOrder.LatestAlarm) {
      return sortByLatestAlarmDate(a, b)
    }
    if (sortOrder === SortOrder.Criticality) {
      return sortByCriticality(a, b)
    }
    if (sortOrder === SortOrder.Unopened) {
      return sortByUnopenedAssetCard(a, b, openedAlarms)
    }
    if (sortOrder === SortOrder.Kilometer) {
      return sortByKilometer(a, b)
    }

    return sortByName(a, b)
  })
