import { useMemo } from 'react'
import { subYears } from 'date-fns'
import type {
  ManualAssetStatusBehaviourHook,
  EnterOutOfOrderParam,
  ExitOutOfOrderParam,
} from 'src/Features/ManualAssetStatus/ManualAssetStatusBehaviourHook'
import { ManualAssetStatusFormMode } from 'src/Features/ManualAssetStatus/ManualAssetStatusForm'
import type { AssetStatusEntry } from 'src/Types/AssetStatusEntry'
import type { ManualAssetStatusModel } from './types'
import { ASSET_STATUS_MAX_END_TIME } from './constants'
import { useAssetStatusEntryCauses } from 'src/Hooks/NetworkData/useAssetStatusEntryCauses'
import {
  useAssetStatusEntries,
  useAssetStatusOutOfOrderEnter,
  useAssetStatusOutOfOrderExit,
} from 'src/Hooks/NetworkData/useAssetStatusEntries'
import { useLanguage } from 'src/Hooks/useLanguage'

const mapToAssetStatusViewModel = ({
  baneDataId,
  status,
  startTime,
  endTime,
  domain,
  causeId,
  startComment,
  endComment,
  isManual,
}: AssetStatusEntry): ManualAssetStatusModel => {
  return {
    baneDataId,
    status,
    startTime,
    endTime,
    domain,
    causeId,
    enterOutOfOrderComment: startComment,
    exitOutOfOrderComment: endComment,
    isManual,
  }
}

const isOpenEntry = (entry: AssetStatusEntry) => entry.endTime > Date.now()

export const useManualAssetStatusBehaviour: ManualAssetStatusBehaviourHook = ({ baneDataId, domain, fromDate, toDate }) => {
  const { currentLanguage } = useLanguage()
  const { assetStatusCauses, isLoading: isCausesLoading } = useAssetStatusEntryCauses()
  const { mutate: enterOutOfOrderMutation, isLoading: isEnterMutationLoading } = useAssetStatusOutOfOrderEnter()
  const { mutate: exitOutOfOrderMutation, isLoading: isExitMutationLoading } = useAssetStatusOutOfOrderExit()

  // To get the current status of the asset, we need to fetch all back one year ago.
  const oneYearAgo = useMemo(() => subYears(Date.now(), 1).getTime(), [])
  const { assetStatusEntries, isLoading: isAssetStatusLoading } = useAssetStatusEntries({
    baneDataIds: [baneDataId],
    domain,
    fromDate: oneYearAgo,
  })

  const latestEntries = useMemo(
    () =>
      assetStatusEntries
        ?.filter(entry => entry.baneDataId === baneDataId && entry.domain === domain)
        .sort((a, b) => b.startTime - a.startTime),
    [assetStatusEntries, baneDataId, domain]
  )
  const latestEntry = latestEntries?.length ? latestEntries[0] : undefined
  const openEntries = latestEntries?.filter(isOpenEntry)
  const openEntry = openEntries?.length ? openEntries[0] : undefined
  const formMode = openEntries?.length ? ManualAssetStatusFormMode.ExitOutOfOrder : ManualAssetStatusFormMode.EnterOutOfOrder
  const updateEntry = openEntry || latestEntry

  const enterOutOfOrder = async (params: EnterOutOfOrderParam) => {
    const startTime = Date.now()
    const endTime = typeof params.endTime === 'number' ? params.endTime : ASSET_STATUS_MAX_END_TIME.getTime()
    enterOutOfOrderMutation({
      baneDataId,
      startTime: new Date(startTime),
      endTime: new Date(endTime),
      causeId: params.cause,
      domain,
      comment: params.comment,
    })
  }
  const exitOutOfOrder = async (params: ExitOutOfOrderParam) => {
    if (!openEntry) {
      return
    }
    exitOutOfOrderMutation({
      baneDataId,
      startTime: new Date(openEntry.startTime),
      causeId: openEntry.causeId,
      domain,
      comment: params.comment,
    })
  }

  const formDisabled = useMemo(
    () => isCausesLoading || isAssetStatusLoading || isEnterMutationLoading || isExitMutationLoading,
    [isEnterMutationLoading, isExitMutationLoading, isAssetStatusLoading, isCausesLoading]
  )

  const availableCauses = useMemo(
    () => assetStatusCauses?.map(({ id, name }) => ({ id, name: name[currentLanguage] })),
    [assetStatusCauses, currentLanguage]
  )

  const assetModels = useMemo(
    () =>
      assetStatusEntries
        ?.filter(
          entry => (updateEntry && updateEntry.startTime === entry.startTime) || entry.startTime >= fromDate || isOpenEntry(entry)
        )
        ?.map(mapToAssetStatusViewModel),
    [assetStatusEntries, fromDate, updateEntry]
  )

  return {
    assetStatusEntries: assetModels || [],
    availableCauses: availableCauses || [],
    formDisabled,
    formMode,
    enterOutOfOrder,
    exitOutOfOrder,
  }
}
