import type { ReactNode } from 'react'
import { Box, Grid } from '@mui/material'

import { Definition } from 'src/Components/Definition'
import { AlarmTable } from './AlarmTable'

import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import type { AssetStatusWindow, AssetStatusWindowStatus } from 'src/Types/AssetTypes'
import { AssetType } from 'src/Types/AssetTypes'

import { getAlarms, getAlarmTypeOccurrences } from 'src/Utils/alarms'
import { formatWindowDuration } from 'src/Utils/statusWindow'
import { useLanguage } from 'src/Hooks/useLanguage'
import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'

import themeColors from 'src/theme'
import type { Language } from 'src/Types/LanguageTypes'
import { OutOfOrderTable } from 'src/Features/Alarms/AlarmList/OutOfOrderTable'
import type { TFunction } from 'i18next'
import { useHideCriticality } from 'src/Hooks/useHideCriticality'

type OwnProps = {
  assetWithAlarms: AssetWithAlarms
  showAlarmsInMaintenance: boolean
  children: ReactNode
}

const getWindowStatus = (
  assetStatusWindows: AssetStatusWindow[] | undefined,
  outOfOrderWindow: AssetStatusWindow[] | undefined,
  t: TFunction<string, undefined>,
  currentLanguage: Language
) => {
  let windowStatus: AssetStatusWindowStatus | 'Both' | undefined
  const maintenanceWindow = assetStatusWindows
    ?.filter(w => w.status === 'Maintenance' && w.startTime < Date.now())
    .map(statusWindow => formatWindowDuration(statusWindow, t, currentLanguage))
  if (maintenanceWindow?.length && outOfOrderWindow?.length) {
    windowStatus = 'Both'
  } else if (maintenanceWindow?.length) {
    windowStatus = 'Maintenance'
  } else if (outOfOrderWindow?.length) {
    windowStatus = 'Out_of_Order'
  }
  return windowStatus
}

export const DetailedAlarms = ({ assetWithAlarms, showAlarmsInMaintenance, children }: OwnProps) => {
  const { t, currentLanguage } = useLanguage()
  const isMediaSM = useBreakpointDown('sm')

  const { alarms: assetAlarms, asset } = assetWithAlarms

  const componentCriticality = asset.componentCriticalityLocal
  const totalCriticality = asset.totalCriticalityAbsolute
  const alarmCriticality = asset.alarmCriticalityAbsolute

  const alarmTypeOccurrences = getAlarmTypeOccurrences(assetAlarms)

  const alarms = getAlarms(assetAlarms, asset, 'Alarm')
  const warnings = getAlarms(assetAlarms, asset, 'Warning')

  const hasCriticalities = totalCriticality !== 0 && componentCriticality !== 0 && alarmCriticality !== 0

  const hideCriticality = useHideCriticality()

  const outOfOrderWindow = asset.assetStatusWindows?.filter(w => w.status === 'Out_of_Order' && w.startTime < Date.now())

  const windowStatus = getWindowStatus(asset.assetStatusWindows, outOfOrderWindow, t, currentLanguage)

  return (
    <>
      <Box mt={2} mb={2} ml={isMediaSM ? 1 : 0}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid item xs={12} sm={12} lg={hasCriticalities ? 3 : 12}>
            <Definition term={t('general.baneDataId')} description={asset.baneDataId} />
          </Grid>
          {(asset.assetType === AssetType.SwitchMachineHeat || asset.assetType === AssetType.SwitchPointMachine) &&
            asset.switchType && (
              <Grid item xs={12} sm={12} lg={hasCriticalities ? 9 : 12}>
                <Definition
                  term={t('switches.statusLabels.maintenance.switchType')}
                  description={asset.switchType?.[currentLanguage]}
                />
              </Grid>
            )}
          {!hideCriticality && (
            <>
              {componentCriticality !== 0 && (
                <Grid item xs={12} sm={4} lg={3}>
                  <Definition term={t('general.componentCriticality')} description={componentCriticality} />
                </Grid>
              )}
              {alarmCriticality !== 0 && (
                <Grid item xs={12} sm={4} lg={3}>
                  <Definition term={t('general.alarmCriticality')} description={alarmCriticality} />
                </Grid>
              )}
              {totalCriticality !== 0 && (
                <Grid item xs={12} sm={4} lg="auto">
                  <Definition term={t('general.totalCriticality')} description={totalCriticality} />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
      <div>
        {outOfOrderWindow && outOfOrderWindow.length > 0 && (
          <OutOfOrderTable
            outOfOrderWindow={outOfOrderWindow}
            chipColor={themeColors.maintenance}
            subHeaderKey={SubHeaderKey.OutOfOrderTable}
          />
        )}
        {alarms.length > 0 && (
          <AlarmTable
            alarms={alarms}
            asset={asset}
            occurrences={alarmTypeOccurrences.Alarm}
            chipColor={themeColors.error}
            showAlarmsInMaintenance={showAlarmsInMaintenance}
            windowStatus={windowStatus}
            expandFirstAlarmChart
            subHeaderKey={SubHeaderKey.AlarmTable}
          />
        )}
        {warnings.length > 0 && (
          <AlarmTable
            alarms={warnings}
            asset={asset}
            occurrences={alarmTypeOccurrences.Warning}
            chipColor={themeColors.warning}
            showAlarmsInMaintenance={showAlarmsInMaintenance}
            windowStatus={windowStatus}
            expandFirstAlarmChart={alarms.length === 0}
            subHeaderKey={SubHeaderKey.WarningTable}
          />
        )}
      </div>
      <Box mt={2}>{children}</Box>
    </>
  )
}
