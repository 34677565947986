import type { AssetStatusWindow, AssetStatusWindowStatus } from 'src/Types/AssetTypes'
import { formatLocalDateTimeShort } from './format'
import type { TFunction } from 'i18next'
import type { Language } from 'src/Types/LanguageTypes'

export const findActiveStatusWindow = (
  assetStatusWindows?: AssetStatusWindow[],
  date: number = Date.now(),
  windowType: AssetStatusWindowStatus[] | undefined = undefined
) => assetStatusWindows?.find(w => w.startTime <= date && w.endTime > date && (!windowType || windowType.includes(w.status)))

export const findFutureStatusWindows = (
  assetStatusWindows?: AssetStatusWindow[],
  date: number = Date.now(),
  windowType: AssetStatusWindowStatus[] | undefined = undefined
) =>
  [...(assetStatusWindows || [])]
    .filter(w => !windowType || windowType.includes(w.status))
    .filter(w => w.startTime > date)
    .sort((a, b) => a.startTime - b.startTime)

export const findFirstFutureStatusWindow = (
  assetStatusWindows?: AssetStatusWindow[],
  date: number = Date.now(),
  windowType: AssetStatusWindowStatus[] | undefined = undefined
): AssetStatusWindow | undefined => findFutureStatusWindows(assetStatusWindows, date, windowType)[0]

export const getWindowDuration = (statusWindow: AssetStatusWindow, t: TFunction, currentLanguage: Language) => {
  const endYear = new Date(statusWindow.endTime).getFullYear()
  const startTime = formatLocalDateTimeShort(statusWindow.startTime, currentLanguage)
  const endTime = endYear > 9000 ? t('statusWindow.noEndDate') : formatLocalDateTimeShort(statusWindow.endTime, currentLanguage)
  return { startTime, endTime }
}

export const formatWindowDuration = (statusWindow: AssetStatusWindow, t: TFunction, currentLanguage: Language) => {
  const { startTime, endTime } = getWindowDuration(statusWindow, t, currentLanguage)
  return `${startTime} - ${endTime}`
}

export const findNearestPastWindow = (
  assetStatusWindows?: AssetStatusWindow[],
  date: number = Date.now()
): AssetStatusWindow | undefined =>
  [...(assetStatusWindows || [])].filter(w => w.endTime < date).sort((a, b) => b.startTime - a.startTime)[0]
