import { callApiGet, callApiPost } from 'src/msGraphClient'

import { ApiType } from 'src/Types/ApiType'

import config from 'src/Config/Config'
import type {
  AssetStatusEntry,
  AssetStatusEntryDomain,
  AssetStatusEntityStatus,
  AssetStatusCause,
} from 'src/Types/AssetStatusEntry'
import { toLocalISO8601 } from 'src/Utils/format'

const { ASSET_STATUS_OUT_OF_ORDER, ASSET_STATUS_ENTER_OUT_OF_ORDER, ASSET_STATUS_EXIT_OUT_OF_ORDER } = config.API_FRAGMENTS.apim

type GetAssetStatusOutOfOrderParams = {
  baneDataIds: string[]
  fromDate: number
  domain: AssetStatusEntryDomain
}

type AssetStatusEntryContract = {
  baneDataId: string
  status: string
  startTime: number
  endTime: number
  causeId: number
  startComment: string
  endComment: string | null
  createdTime?: number
  createdBy?: string
  lastUpdatedTime?: number
  lastUpdatedBy?: string
  domain: string
  isManual: boolean
  cause: AssetStatusCause
}
export const getAssetStatusOutOfOrder = async ({ baneDataIds, fromDate, domain }: GetAssetStatusOutOfOrderParams) => {
  const urlParams = new URLSearchParams({
    baneDataIds: baneDataIds.join(','),
    fromDate: new Date(fromDate).toJSON(),
    domain,
  })

  const result = await callApiGet<AssetStatusEntryContract[]>(
    `${ASSET_STATUS_OUT_OF_ORDER}?${urlParams.toString()}`,
    ApiType.APIM,
    { parseDates: true }
  )
  return result.map<AssetStatusEntry>(assetStatus => {
    const {
      baneDataId,
      status,
      startTime,
      endTime,
      causeId,
      createdTime,
      startComment,
      endComment,
      lastUpdatedTime,
      lastUpdatedBy,
      createdBy,
      domain,
      isManual,
      cause,
    } = assetStatus
    return {
      baneDataId,
      status: status as AssetStatusEntityStatus,
      startTime,
      endTime,
      causeId,
      startComment,
      endComment: endComment || undefined,
      domain: domain as AssetStatusEntryDomain,
      createdTime,
      createdBy,
      lastUpdatedTime,
      lastUpdatedBy,
      isManual,
      cause,
    }
  })
}

type AssetStatusEnterOutOfOrderParams = {
  baneDataId: string
  startTime: Date
  endTime: Date
  causeId: number
  domain: AssetStatusEntryDomain
  comment: string
}

export const postAssetStatusOutOfOrderEnter = async (params: AssetStatusEnterOutOfOrderParams) => {
  const body = JSON.stringify({
    ...params,
    startTime: toLocalISO8601(params.startTime),
    endTime: toLocalISO8601(params.endTime),
  })
  await callApiPost<string>(ASSET_STATUS_ENTER_OUT_OF_ORDER, ApiType.APIM, body)
}

type AssetStatusExitOutOfOrderParams = {
  baneDataId: string
  startTime: Date
  causeId: number
  domain: AssetStatusEntryDomain
  comment: string
}

export const postAssetStatusOutOfOrderExit = async (params: AssetStatusExitOutOfOrderParams) => {
  const body = JSON.stringify({
    ...params,
    startTime: toLocalISO8601(params.startTime),
  })
  await callApiPost<string>(ASSET_STATUS_EXIT_OUT_OF_ORDER, ApiType.APIM, body)
}
