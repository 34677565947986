import type { GetOrchestratedAssetsParams } from 'src/Types/GetOrchestratedAssetsParams'

export const getOrchestratedAssetIdParams = (idParams: GetOrchestratedAssetsParams) => {
  if ('baneDataIds' in idParams) {
    return { idKey: 'baneDataIds', value: idParams.baneDataIds.join(',') }
  }

  if ('locationIds' in idParams) {
    return { idKey: 'locationIds', value: idParams.locationIds.join(',') }
  }

  return { idKey: 'railwayId', value: idParams.railwayId }
}
