import type { MouseEvent, ReactNode } from 'react'
import { MenuItem as MaterialMenuItem, styled } from '@mui/material'

const StyledMaterialMenuItem = styled(MaterialMenuItem)`
  display: flex;
  gap: 0.5em;
`

type OwnProps = {
  children?: ReactNode
  onClick?: (event: MouseEvent) => void
}

export const MenuItem = ({ children, onClick }: OwnProps) => {
  return (
    <StyledMaterialMenuItem autoFocus onClick={onClick}>
      {children}
    </StyledMaterialMenuItem>
  )
}

export default MenuItem
