import type { SystemStatus } from 'src/Types/SystemStatus'
import { AssetType } from 'src/Types/AssetTypes'

/**
 * FYI: Unknown is a.k.a. No swings / Ingen omlegginger
 */
export const ALL_STATUS_OPTIONS: SystemStatus[] = ['Ok', 'Alarm', 'Warning', 'Unknown', 'Out_of_Order', 'NotMonitored']

export const ALL_SYSTEM_OPTIONS = [
  AssetType.TrackCircuit,
  AssetType.SwitchPointMachine,
  AssetType.SwitchMachineHeat,
  AssetType.SwitchHeat,
  AssetType.Bender,
  AssetType.Rack,
]
