import type { SxProps } from '@mui/system'
import type { Theme } from '@mui/material/styles'
import CancelIcon from '@mui/icons-material/CancelOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined'
import PendingIcon from '@mui/icons-material/HelpOutlined'
import UnknownIcon from '@mui/icons-material/RadioButtonUncheckedOutlined'
import OutOfOrderIcon from '@mui/icons-material/Block'
import MaintenanceIcon from '@mui/icons-material/Build'

import type { SystemStatus } from 'src/Types/SystemStatus'

import themeColors from 'src/theme'

type OwnProps = {
  alarmIndicator: SystemStatus | 'OkToday'
  size?: 'small' | 'large' | 'xsmall' | 'medium'
}

const getIndicatorIcon = (alarmIndicator: SystemStatus | 'OkToday') => {
  switch (alarmIndicator) {
    case 'Ok':
      return CheckCircleIcon
    case 'OkToday':
      return CheckCircleIcon
    case 'Pending':
      return PendingIcon
    case 'Unknown':
      return UnknownIcon
    case 'Out_of_Order':
      return OutOfOrderIcon
    case 'Maintenance':
      return MaintenanceIcon
    default:
      return CancelIcon
  }
}

const getColor = (alarmIndicator: SystemStatus | 'OkToday') => {
  switch (alarmIndicator) {
    case 'Alarm':
      return themeColors.error
    case 'Warning':
      return themeColors.warning
    case 'Ok':
    case 'Unknown':
      return themeColors.success
    case 'OkToday':
      return themeColors.successText
    case 'Pending':
    case 'Out_of_Order':
      return themeColors.disabledText
    case 'Maintenance':
      return themeColors.maintenance
    default:
      return undefined
  }
}

export const AlarmIndicatorIcon = ({ alarmIndicator, size = 'medium' }: OwnProps) => {
  const IndicatorIcon = getIndicatorIcon(alarmIndicator)
  const color = getColor(alarmIndicator)
  const sx: SxProps<Theme> = { color }
  if (size === 'xsmall') {
    sx.fontSize = 16
  }
  return <IndicatorIcon aria-hidden="true" fontSize={size !== 'xsmall' ? size : undefined} sx={sx} />
}
