import type { AssetStatusEntryDomain, AssetStatusEntityStatus } from 'src/Types/AssetStatusEntry'

export type ManualAssetStatusModel = {
  baneDataId: string
  status: AssetStatusEntityStatus
  startTime: number
  endTime: number | undefined
  domain: AssetStatusEntryDomain
  causeId: number
  enterOutOfOrderComment: string | undefined
  exitOutOfOrderComment: string | undefined
  isManual: boolean
}

export type ManualAssetStatusCause = {
  id: number
  name: string
}

export enum ChangeManualAssetStatusEventType {
  Enter,
  Exit,
}

export type EnterOutOfOrderEvent = {
  type: ChangeManualAssetStatusEventType.Enter
  cause: number
  endTime: number | undefined
  comment: string
}

export type ExitOutOfOrderEvent = {
  type: ChangeManualAssetStatusEventType.Exit
  comment: string
}

export type ChangeManualAssetStatusEvent = EnterOutOfOrderEvent | ExitOutOfOrderEvent
