import { styled } from '@mui/material/styles'
import type { ManualAssetStatusModel, ManualAssetStatusCause } from 'src/Features/ManualAssetStatus/types'
import { formatLocalDateTime } from 'src/Utils/format'
import { useLanguage } from 'src/Hooks/useLanguage'
import theme from 'src/theme'

const StyledHistoryList = styled('ul')`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 0;
  margin: 0;
  margin-top: 1rem;
  font-size: 14px;
`

const StyledHistoryEntry = styled('li')`
  padding: 0 0 5px 0;
  margin: 0;
  list-style: none;

  :not(:last-child) {
    border-bottom: solid 1px ${theme.hr};
  }

  > dl {
    margin: 0 0 0 10px;
    line-height: 1.3;

    > dt,
    > dd {
      margin: 0;
      padding: 0;
      display: inline;
    }

    > dt {
      font-weight: bold;
      clear: left;
      margin-right: 0.2em;
    }

    > dd:after {
      content: '';
      display: block;
    }
  }
`

const StyledHeader = styled('div')`
  font-weight: bold;
`

export type ManualAssetStatusHistoryProps = {
  assetStatusLog: ManualAssetStatusModel[]
  availableCauses: ManualAssetStatusCause[]
}

const getUniqueKey = (entry: ManualAssetStatusModel) => [entry.baneDataId, entry.domain, entry.startTime].join('_')

export const ManualAssetStatusLog = ({ assetStatusLog, availableCauses }: ManualAssetStatusHistoryProps) => {
  const { t } = useLanguage()

  const sorted = assetStatusLog.sort((a, b) => b.startTime - a.startTime)

  return (
    <StyledHistoryList>
      {sorted.map(entry => {
        const causeName = availableCauses.find(({ id }) => id === entry.causeId)?.name
        return (
          <StyledHistoryEntry key={getUniqueKey(entry)}>
            <StyledHeader>
              {formatLocalDateTime(entry.startTime, false)}
              {' - '}
              {entry.endTime && formatLocalDateTime(entry.endTime, false)}
            </StyledHeader>
            <dl>
              <dt>{t('manualAssetStatus.cause')}:</dt>
              <dd>{causeName}</dd>
              <dt>{t('manualAssetStatus.outOfOrderComment')}:</dt>
              <dd>{entry.enterOutOfOrderComment}</dd>
              <dt>{t('manualAssetStatus.normalOperationComment')}:</dt>
              <dd>{entry.exitOutOfOrderComment}</dd>
            </dl>
          </StyledHistoryEntry>
        )
      })}
    </StyledHistoryList>
  )
}
