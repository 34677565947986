import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye'
import { IconWithText } from 'src/Components/IconWithText'
import { ignoreStyleProps } from 'src/Utils/style'
import themeColors from 'src/theme'

type AlarmSize = 'large' | 'small'

const StyledIconContainer = styled('div')`
  color: ${themeColors.success};
`

const StyledPanoramaFishEyeIcon = styled(PanoramaFishEyeIcon, ignoreStyleProps('iconSize'))<{ iconSize: AlarmSize }>`
  vertical-align: text-bottom;
  margin-left: ${props => (props.iconSize === 'small' ? '5px' : 0)};
`

export const UnknownIcon = () => {
  const { t } = useTranslation()

  return (
    <StyledIconContainer>
      <IconWithText
        aria-label={t('switches.statusLabels.alarms.unknown')}
        icon={<StyledPanoramaFishEyeIcon fontSize="large" iconSize="large" />}
        text=""
        isUpperCase
        gap={0.42}
      />
    </StyledIconContainer>
  )
}
