/**
 * AssetStatusEntity contains the types referring to BN.M40.AssetStatus
 */

/**
 * This is AssetStatusDto in Orchestrate...
 */
export type AssetStatusEntry = {
  baneDataId: string
  status: AssetStatusEntityStatus
  startTime: number
  endTime: number
  causeId: number
  domain: AssetStatusEntryDomain
  startComment: string
  endComment?: string
  isManual: boolean
  createdTime?: number
  lastUpdatedTime?: number
  createdBy?: string
  lastUpdatedBy?: string
  cause?: AssetStatusCause
}

export type AssetStatusCause = { name_No: string; name_En: string; description_URL?: string | null }

export enum AssetStatusEntityStatus {
  OutOfOrder = 'Out_of_Order',
  Maintenance = 'Maintenance',
}

export type AssetStatusEntryCause = {
  id: number
  name: {
    no: string
    en: string
  }
}

export enum AssetStatusEntryDomain {
  TrackCircuit = 'TrackCircuit',
  PointMachine = 'PointMachine',
  SwitchHeat = 'SwitchHeat',
  SignalEarthFault = 'SignalEarthFault',
  Vibration = 'Vibration',
  SurveillanceSystem = 'SurveillanceSystem',
  DerailerMachine = 'DerailerMachine',
  MachineHeat = 'MachineHeat',
}
