import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

import { useTranslation } from 'react-i18next'
import { useAccess } from 'src/Hooks/useAccess'
import { useHideCriticality } from 'src/Hooks/useHideCriticality'

import type { Asset } from 'src/Types/AssetTypes'
import { AssetType } from 'src/Types/AssetTypes'
import { MenuWithButton } from 'src/Components/UIComponents'
import config from 'src/Config/Config'
import { PermalinkMenuItem, FavoriteMenuItem, ManualAssetStatusMenuItem, CriticalityResetMenuItem } from './index'
import { getCardIdByAsset } from 'src/Utils/cardId'

type OwnProps = {
  asset: Asset
}

export const AssetCardActionsMenu = ({ asset }: OwnProps) => {
  const { t } = useTranslation()

  const hasAssetStatusWriteAccess = useAccess('AssetStatus-OutOfOrder.Write')
  const supportsManualAssetStatus = [AssetType.SwitchPointMachine, AssetType.SwitchMachineHeat, AssetType.TrackCircuit].includes(
    asset.assetType
  )
  const showOutOfOrderSetterOption =
    config.FEATURES?.MANUAL_ASSET_STATUS && hasAssetStatusWriteAccess && supportsManualAssetStatus

  const hasAlarmCriticalityResetAccess = useAccess('Statistics.Read')
  const supportsCriticalityReset = !useHideCriticality()
  const showCriticalityResetOption = hasAlarmCriticalityResetAccess && supportsCriticalityReset

  return (
    <MenuWithButton
      id={`${getCardIdByAsset(asset)}-actions`}
      tooltip={t('general.showActions')}
      buttonStartIcon={<MoreHorizIcon />}
    >
      <PermalinkMenuItem asset={asset} />
      <FavoriteMenuItem asset={asset} />
      {showOutOfOrderSetterOption && <ManualAssetStatusMenuItem asset={asset} />}
      {showCriticalityResetOption && <CriticalityResetMenuItem asset={asset} />}
    </MenuWithButton>
  )
}
