import { styled } from '@mui/material/styles'
import Chip from '@mui/material/Chip'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'

import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'

import type { AssetStatusWindow } from 'src/Types/AssetTypes'

import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { useLanguage } from 'src/Hooks/useLanguage'
import { ignoreStyleProps } from 'src/Utils/style'
import type { SubHeaderKey } from 'src/Providers/Settings/SubHeader'
import { Language } from 'src/Types/LanguageTypes'
import { OutOfOrderEntry } from 'src/Features/Alarms/AlarmList/OutOfOrderEntry'

type StyleProps = {
  chipColor: string
}

const StyledChip = styled(Chip, ignoreStyleProps('chipColor'))<StyleProps>`
  margin-left: 8px;
  color: white;
  background-color: ${props => props.chipColor};
`

const TableCellPeriod = styled(TableCell)(
  props => `
  min-width: 150px;
  ${props.theme.breakpoints.down('sm')} {
    min-width: 140px;
  }
`
)

type OwnProps = {
  outOfOrderWindow: AssetStatusWindow[] | undefined
  chipColor: string
  subHeaderKey: SubHeaderKey
}

const getOutOfOrderRows = (outOfOrderWindow: AssetStatusWindow[] | undefined, currentLanguage: Language) => {
  const outOfOrderRows: OutOfOrderTableRow[] | undefined = outOfOrderWindow?.map(statusWindow => {
    const cause = currentLanguage === Language.no ? statusWindow.cause.name_No : statusWindow.cause.name_En

    return {
      id: statusWindow.causeId,
      startTime: statusWindow.startTime,
      endTime: statusWindow.endTime,
      cause,
      descriptionUrl: statusWindow.cause.description_URL,
    }
  })
  return outOfOrderRows
}

export type OutOfOrderTableRow = {
  id: number
  startTime: number
  endTime: number
  cause: string
  descriptionUrl?: string | null
}

export const OutOfOrderTable = ({ outOfOrderWindow, chipColor, subHeaderKey }: OwnProps) => {
  const { t, currentLanguage } = useLanguage()
  const isMediaLG = useBreakpointDown('lg')
  const isMediaSM = useBreakpointDown('sm')

  const outOfOrderRows = getOutOfOrderRows(outOfOrderWindow, currentLanguage)
  const numOccurrences = outOfOrderRows?.length ?? 0
  const maintenanceClassification = t('general.outOfOrderPeriod')

  return (
    <SubHeaderWithExpandableInfo
      title={
        <b>
          {maintenanceClassification}
          <StyledChip chipColor={chipColor} label={numOccurrences} size="small" />
        </b>
      }
      hideText={t('alarms.hide', {
        classification: maintenanceClassification?.toLowerCase(),
      })}
      isDefaultExpanded
      subHeaderKey={subHeaderKey}
    >
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {isMediaSM ? (
                <TableCellPeriod padding="checkbox">
                  <b>{t('alarms.alarmEntry.period')}</b>
                </TableCellPeriod>
              ) : (
                <>
                  <TableCell padding="checkbox">
                    <b>{t('alarms.alarmEntry.from')}</b>
                  </TableCell>
                  <TableCell padding="checkbox">
                    <b>{t('alarms.alarmEntry.to')}</b>
                  </TableCell>
                </>
              )}
              <TableCell padding="checkbox">
                <b>{t('manualAssetStatus.cause')}</b>
              </TableCell>
              <TableCell padding="checkbox" title={t('manualAssetStatus.outOfOrderDescriptionsUrl.long')}>
                <b>{t(`manualAssetStatus.outOfOrderDescriptionsUrl.${isMediaLG ? 'short' : 'medium'}`)}</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {outOfOrderRows?.map(row => (
              <OutOfOrderEntry
                key={row.id}
                startTime={row.startTime}
                endTime={row.endTime}
                cause={row.cause}
                descriptionUrl={row.descriptionUrl}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </SubHeaderWithExpandableInfo>
  )
}
