import { styled } from '@mui/material/styles'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { formatLocalDateTime } from 'src/Utils/format'

import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { useLanguage } from 'src/Hooks/useLanguage'
import themeColors from 'src/theme'

const StyledDescriptionLink = styled('a')`
  font-size: 14px;
  color: ${themeColors.linkText};
`

type OwnProps = {
  startTime: number
  endTime: number
  cause: string
  descriptionUrl?: string | null
}

export const OutOfOrderEntry = ({ startTime, endTime, cause, descriptionUrl }: OwnProps) => {
  const { t } = useLanguage()
  const isMediaSM = useBreakpointDown('sm')

  const cellEntry = (value: string | number, title?: string) => <div title={title}>{value}</div>

  const descriptionUrlOrDefault =
    descriptionUrl && descriptionUrl.length > 0
      ? descriptionUrl
      : 'https://banenor.sharepoint.com/sites/DT_Sviktmodebibliotek/SitePages/GenereltOmAppen.aspx#ustand'

  return (
    <TableRow>
      {isMediaSM ? (
        <TableCell padding="checkbox">
          {startTime && cellEntry(`${formatLocalDateTime(startTime)} -`)}
          {endTime < Date.now() ? cellEntry(formatLocalDateTime(endTime)) : t('statusWindow.noEndDate')}
        </TableCell>
      ) : (
        <>
          <TableCell padding="checkbox">{startTime && cellEntry(formatLocalDateTime(startTime))}</TableCell>
          <TableCell padding="checkbox">
            {cellEntry(endTime < Date.now() ? formatLocalDateTime(endTime) : t('statusWindow.noEndDate'))}
          </TableCell>
        </>
      )}
      <TableCell padding="checkbox">{cellEntry(cause, t('manualAssetStatus.cause'))}</TableCell>
      <TableCell padding="checkbox" title={t('manualAssetStatus.outOfOrderDescriptionsUrl.long')}>
        <StyledDescriptionLink href={descriptionUrlOrDefault} target="_blank" rel="noopener noreferrer">
          <OpenInNewIcon fontSize="small" />
        </StyledDescriptionLink>
      </TableCell>
    </TableRow>
  )
}
