import { useQueryWithErrorHandling } from 'src/Hooks/NetworkData/useQueryWithErrorHandling'
import { getAssetStatusEntryCauses } from 'src/Providers/AssetStatusCause'

export const useAssetStatusEntryCauses = () => {
  const query = useQueryWithErrorHandling(
    ['assetStatusEntryCauses'],
    () => getAssetStatusEntryCauses(),
    {
      staleTime: 5 * 60 * 1000,
    },
    'manualAssetStatus.fetchError'
  )

  return {
    ...query,
    assetStatusCauses: query.data,
  }
}
