import { styled } from '@mui/material/styles'
import Chip from '@mui/material/Chip'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'

import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { AlarmEntry } from './AlarmEntry'

import type { AlarmType } from 'src/Types/AlarmType'
import { InternalAlarmSystem } from 'src/Types/AlarmSystem'
import type { Alarm } from 'src/Types/Alarm'
import type { Asset, AssetStatusWindowStatus } from 'src/Types/AssetTypes'

import type { AlarmTypeOccurrences } from 'src/Utils/alarms'
import { getAlarmStart, isSwitchHeatAlarmWithHeatElements } from 'src/Utils/alarms'
import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { useLanguage } from 'src/Hooks/useLanguage'
import { ignoreStyleProps } from 'src/Utils/style'
import type { SubHeaderKey } from 'src/Providers/Settings/SubHeader'

type StyleProps = {
  chipColor: string
}

const StyledChip = styled(Chip, ignoreStyleProps('chipColor'))<StyleProps>`
  margin-left: 8px;
  color: white;
  background-color: ${props => props.chipColor};
`

const TableCellAlarmType = styled(TableCell)(
  props => `
  min-width: 130px;
  ${props.theme.breakpoints.down('sm')} {
    min-width: 120px;
  }
  `
)

const TableCellPeriod = styled(TableCell)(
  props => `
  min-width: 150px;
  ${props.theme.breakpoints.down('sm')} {
    min-width: 140px;
  }
`
)

const uniqueAlarmId = (alarm: Alarm, i: number) => {
  return `${alarm.alarmTypeId}_${getAlarmStart(alarm)}_${alarm.baneDataId}_${i}`
}

type OwnProps = {
  alarms: {
    alarm: Alarm
    alarmType: AlarmType
  }[]
  asset: Asset
  occurrences: AlarmTypeOccurrences
  chipColor: string
  showAlarmsInMaintenance: boolean
  windowStatus?: AssetStatusWindowStatus | 'Both'
  expandFirstAlarmChart?: boolean
  subHeaderKey: SubHeaderKey
}

export const AlarmTable = ({
  alarms,
  asset,
  occurrences,
  chipColor,
  showAlarmsInMaintenance,
  windowStatus,
  expandFirstAlarmChart = false,
  subHeaderKey,
}: OwnProps) => {
  const { t, currentLanguage } = useLanguage()
  const isMediaLG = useBreakpointDown('lg')
  const isMediaSM = useBreakpointDown('sm')

  const alarmClassification = alarms.length ? alarms[0].alarmType.classification.localization.full[currentLanguage] : undefined
  const numOccurrences = showAlarmsInMaintenance ? occurrences.total : occurrences.real

  const hasSwitchAlarm = alarms.some(
    ({ alarm }) =>
      alarm.internalAlarmSystem === InternalAlarmSystem.AggregatedPointMachineAlarm ||
      alarm.internalAlarmSystem === InternalAlarmSystem.AggregatedMachineHeatAlarm
  )

  const hasSwitchHeatAlarm = alarms.some(({ alarm }) => isSwitchHeatAlarmWithHeatElements(alarm))

  return (
    <SubHeaderWithExpandableInfo
      title={
        <b>
          {alarmClassification}
          <StyledChip chipColor={chipColor} label={numOccurrences} size="small" />
        </b>
      }
      hideText={t('alarms.hide', {
        classification: alarmClassification?.toLowerCase(),
      })}
      isDefaultExpanded
      subHeaderKey={subHeaderKey}
    >
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCellAlarmType padding="checkbox">
                <b>{t('alarms.alarmEntry.type')}</b>
              </TableCellAlarmType>
              <TableCell padding="checkbox">
                <b>{t(`alarms.alarmEntry.number.${isMediaSM ? 'short' : 'long'}`)}</b>
              </TableCell>
              {isMediaSM ? (
                <TableCellPeriod padding="checkbox">
                  <b>{t('alarms.alarmEntry.period')}</b>
                </TableCellPeriod>
              ) : (
                <>
                  <TableCell padding="checkbox">
                    <b>{t('alarms.alarmEntry.from')}</b>
                  </TableCell>
                  <TableCell padding="checkbox">
                    <b>{t('alarms.alarmEntry.to')}</b>
                  </TableCell>
                </>
              )}
              {(hasSwitchAlarm || hasSwitchHeatAlarm) && (
                <TableCell padding="checkbox">
                  {hasSwitchAlarm && <b>{t(`alarms.alarmEntry.machineName.${isMediaLG ? 'short' : 'long'}`)}</b>}
                  {hasSwitchAlarm && hasSwitchHeatAlarm && ' / '}
                  <b>{hasSwitchHeatAlarm && t(`alarms.alarmEntry.switchHeatElementName.${isMediaLG ? 'short' : 'long'}`)}</b>
                </TableCell>
              )}
              <TableCell padding="checkbox" title={t('alarms.alarmDescriptionsUrl.long')}>
                <b>{t(`alarms.alarmDescriptionsUrl.${isMediaLG ? 'short' : 'medium'}`)}</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alarms.map(({ alarm }, i) => (
              <AlarmEntry
                alarm={alarm}
                showAlarmsInMaintenance={showAlarmsInMaintenance}
                showExtraAlarmsInfo={hasSwitchAlarm || hasSwitchHeatAlarm}
                windowStatus={windowStatus}
                assetType={asset.assetType}
                expandAlarmChart={expandFirstAlarmChart && i === 0}
                key={uniqueAlarmId(alarm, i)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </SubHeaderWithExpandableInfo>
  )
}
