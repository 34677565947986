import { useMemo, useState } from 'react'
import { useLanguage } from 'src/Hooks/useLanguage'
import type { Asset } from 'src/Types/AssetTypes'
import { ManualAssetStatusDialog } from 'src/Features/ManualAssetStatus/ManualAssetStatusDialog'
import { getAssetStatusDomainByAsset } from 'src/Features/ManualAssetStatus/getAssetStatusDomainByAsset'
import { useFakeManualAssetStatus } from 'src/Features/ManualAssetStatus/test/useFakeManualAssetStatus'
import { useManualAssetStatusBehaviour } from 'src/Features/ManualAssetStatus/useManualAssetStatusBehaviour'
import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'
import { QueryStringStateKeys, useQueryStringState } from 'src/Store/useQueryStringState'
import { getSystemNameByAsset } from 'src/Utils/getSystemNameByAsset'
import { ManualAssetStatusMenuItemButton } from 'src/Components/AssetCard/AssetCardActionsMenu/ManualAssetStatusMenuItemButton'

const USE_FAKE_IMPLEMENTATION = false

type OwnProps = {
  asset: Asset
}

export const ManualAssetStatusMenuItem = ({ asset }: OwnProps) => {
  const { t } = useLanguage()
  const [open, setOpen] = useState(false)
  const [selectedDatePeriod] = useQueryStringState(QueryStringStateKeys.datePeriod)
  const dateRange = useMemo(() => (selectedDatePeriod ? getDateRange(selectedDatePeriod) : undefined), [selectedDatePeriod])

  const domain = getAssetStatusDomainByAsset(asset)

  const handleClick = () => {
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (!dateRange) {
    return null
  }

  if (typeof domain === 'undefined') {
    return null
  }

  const { baneDataId, name } = asset
  const { fromDate, toDate } = dateRange

  const system = getSystemNameByAsset(asset, t)
  const title = `${name} - ${system}`

  return (
    <>
      <ManualAssetStatusMenuItemButton onClick={handleClick} />
      {open && (
        <ManualAssetStatusDialog
          baneDataId={baneDataId}
          title={title}
          domain={domain}
          fromDate={fromDate}
          toDate={toDate}
          useManualAssetStatusBehaviour={USE_FAKE_IMPLEMENTATION ? useFakeManualAssetStatus : useManualAssetStatusBehaviour}
          onClose={handleClose}
        />
      )}
    </>
  )
}

export default ManualAssetStatusMenuItem
