import type { Filter, StatusFilter } from 'src/Types/Filter'
import { FilterType } from 'src/Types/Filter'
import type { Alarm } from 'src/Types/Alarm'
import type { Asset } from 'src/Types/AssetTypes'
import { AssetType } from 'src/Types/AssetTypes'

/**
 * Filter alarms on card view pages other than /alarms.
 * This will return unfiltered alarms when status filter contains Out_of_Order or Maintenance,
 * or the asset is not a SwitchPointMachine or SwitchMachineHeat.
 * Otherwise, return filtered alarms with nrOfRealAlarms > 0.
 */
export const filterRealAlarms = (filters: Filter[], alarms: Alarm[], asset: Asset) => {
  const statusFilter: StatusFilter | undefined = filters.flatMap(f => (f.type === FilterType.Status ? f : []))[0]
  const showAlarmsInMaintenance =
    Boolean(statusFilter?.values.length) &&
    (statusFilter.values.includes('Out_of_Order') || statusFilter.values.includes('Maintenance'))

  if (showAlarmsInMaintenance || ![AssetType.SwitchPointMachine, AssetType.SwitchMachineHeat].includes(asset.assetType)) {
    return alarms
  }

  return alarms.filter(a => a.nrOfRealAlarms > 0)
}
