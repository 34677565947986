import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutlineOutlined'

import { IconWithText } from 'src/Components/IconWithText'

const StyledIconContainer = styled('div')`
  margin-bottom: -17px;
`

export const NotMonitoredIcon = () => {
  const { t } = useTranslation()

  return (
    <StyledIconContainer>
      <IconWithText aria-label={t('alarms.notMonitored')} icon={<ErrorOutlineIcon fontSize="large" />} text="" isUpperCase />
    </StyledIconContainer>
  )
}
