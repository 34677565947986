import { styled } from '@mui/material/styles'
import type { Asset } from 'src/Types/AssetTypes'
import type { SystemStatus } from 'src/Types/SystemStatus'
import { useLanguage } from 'src/Hooks/useLanguage'
import type { TFunction } from 'i18next'
import { ignoreStyleProps } from 'src/Utils/style'
import themeColors from 'src/theme'

type StyledCardStatusTextProps = {
  grey: boolean
}

const StyledCardStatusText = styled(
  'div',
  ignoreStyleProps('grey')
)<StyledCardStatusTextProps>(({ grey, theme }) => {
  return `
    color: ${grey ? themeColors.disabledText : themeColors.primary};
    font-size: 14px;
    display: block;

    ${theme.breakpoints.down('lg')} {
      font-size: 12px;
    }
  `
})

type OwnProps = {
  asset: Asset
  hideCriticality: boolean
}

export const CardStatusText = ({ asset, hideCriticality }: OwnProps) => {
  const { t } = useLanguage()
  const cardStatus: SystemStatus = asset.statusWithAssetStatusWindows
  const toBeGreyed = ['NotMonitored', 'Out_of_Order', 'Maintenance'].includes(cardStatus)
  return <StyledCardStatusText grey={toBeGreyed}>{textForAsset(t, asset, hideCriticality).toUpperCase()}</StyledCardStatusText>
}

const textForAsset = (t: TFunction<string, undefined>, asset: Asset, hideCriticality: boolean) => {
  const cardStatus: SystemStatus = asset.statusWithAssetStatusWindows
  switch (cardStatus) {
    case 'Alarm':
    case 'Warning':
      if (asset.showCriticality) {
        const criticalityValue = asset.totalCriticalityAbsolute
        if (hideCriticality) {
          // hideCriticality does have precedence
          return ''
        }
        return criticalityValue ? `${t('sideMenu.sortOrder.criticality')}: ${criticalityValue}` : ''
      }
      return ''

    case 'NotMonitored':
      return t('alarms.notMonitored')
    case 'Out_of_Order':
      return t('statusWindow.outOfOrder')
    case 'Ok':
      return t('switches.statusLabels.alarms.noAlarms')
    case 'Pending':
      return t('switches.statusLabels.alarms.pendingAlarms_short')

    case 'Unknown':
    default:
      return t('sideMenu.status.unknown')
  }
}
