import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import BlockIcon from '@mui/icons-material/Block'

import { IconWithText } from 'src/Components/IconWithText'

import themeColors from 'src/theme'

const StyledIconContainer = styled('div')`
  color: ${themeColors.disabledText};
`

export const OutOfOrderIcon = () => {
  const { t } = useTranslation()

  return (
    <StyledIconContainer>
      <IconWithText aria-label={t('statusWindow.outOfOrder')} icon={<BlockIcon fontSize="large" />} text="" isUpperCase />
    </StyledIconContainer>
  )
}
