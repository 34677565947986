import type { AlarmClassification } from 'src/Types/AlarmClassification'
import type { AlarmType } from 'src/Types/AlarmType'
import type { Alarm } from 'src/Types/Alarm'
import type { Asset } from 'src/Types/AssetTypes'
import type { NormalizedAlarm } from 'src/Types/NormalizedAlarm'
import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'

import { partition } from './array'
import { useDomainConstantsStore } from 'src/Store/domainConstants'
import { InternalAlarmSystem } from 'src/Types/AlarmSystem'

const ALARM_TYPE_ID_WITH_HEAT_ELEMENTS = [15, 16]

/**
 * Finds the timestamp for the start of the aggregated alarm.
 *
 * @param alarm - Alarm to find the start of.
 */
export const getAlarmStart = (alarm: Alarm | NormalizedAlarm) => {
  // TODO: this should be temporary
  if ('alarmId' in alarm) {
    // is NormalizedAlarm
    return alarm.fromDateTime
  }

  return alarm.firstRealFromDateTime
}

/**
 * Finds the timestamp for the end of the aggregated alarm.
 *
 * @param alarm - Alarm to find the end of.
 */
export const getAlarmEnd = (alarm: Alarm | NormalizedAlarm) => {
  // TODO: this should be temporary, until all alarms are normalized
  if ('alarmId' in alarm) {
    // is NormalizedAlarm
    return alarm.toDateTime
  }

  return alarm.lastRealToDateTime
}

/**
 * Finds the timestamp for the most recent alarm.
 *
 * @param alarms - Assets to find alarms for.
 */
export const getLatestAlarmTime = (alarms: Alarm[]): number | undefined => alarms.map(getAlarmEnd).sort().slice(-1)[0]

export const getAlarmTypeByNormalizedAlarm = ({ alarmTypeId, alarmSystemId }: NormalizedAlarm) => {
  const alarmTypes = useDomainConstantsStore.getState().alarmTypes.alarms
  return alarmTypes.find(at => at.type.id === alarmTypeId && at.type.system === alarmSystemId)
}

export const getAlarmTypeByAlarm = ({ alarmTypeId, alarmSystem }: Alarm) => {
  const alarmTypes = useDomainConstantsStore.getState().alarmTypes.alarms
  return alarmTypes.find(at => at.type.id === alarmTypeId && at.type.system === alarmSystem)
}

export const getAlarmTypesFromAlarms = (alarms: Alarm[]) => [
  ...new Set(alarms.map(alarm => getAlarmTypeByAlarm(alarm)!).filter(t => t)),
]

const classifyAlarms = (alarms: Alarm[]) =>
  alarms.flatMap(alarm => {
    const alarmType = getAlarmTypeByAlarm(alarm)

    if (alarmType) {
      return {
        alarm,
        classification: alarmType.classification.id,
      }
    }
    return []
  })

/**
 * Finds the prioritized alarm with the highest classification for a given Alarm array
 * Optional param: prioritizeWarning to decide if alarm classification warning should
 * be prioritized over alarm
 *
 * @param allAlarms - Alarm array to loop over.
 * @param prioritizeWarning - Boolean to re-prioritize what classification alarm to use
 */
export function getRelevantAlarm(allAlarms: Alarm[], prioritizeWarning?: boolean) {
  const filteredAlarms = allAlarms.filter(a => {
    if ('nrOfRealAlarms' in a) {
      return a.nrOfRealAlarms > 0
    }
    return true
  })

  const classifiedAlarms = classifyAlarms(filteredAlarms).sort(
    (a, b) => (getAlarmEnd(b.alarm) || 0) - (getAlarmEnd(a.alarm) || 0)
  )

  const [alarms, warnings] = partition(classifiedAlarms, alarm => alarm.classification === 'Alarm')

  if (prioritizeWarning) {
    return warnings[0]
  }

  return alarms[0] || warnings[0]
}

/**
 * Uses the alarm type to get a unique id string from the alarmType-system and id.
 *
 * @param alarmType - Alarm type to get unique id from.
 */
export const getAlarmTypeUniqueId = (alarmType: AlarmType) => `${alarmType.type.system}-${alarmType.type.id}`

export type AlarmTypeOccurrences = {
  total: number
  real: number
  filtered: number
}

/**
 * Counts the total of occurrences for each alarmType.
 *
 * @param alarms - Alarms to count occurences.
 */
export const getAlarmTypeOccurrences = (alarms: Alarm[]) => {
  const alarmTypeOccurrences: Record<AlarmClassification, AlarmTypeOccurrences> = {
    Alarm: {
      total: 0,
      real: 0,
      filtered: 0,
    },
    Warning: {
      total: 0,
      real: 0,
      filtered: 0,
    },
  }

  alarms.forEach(alarm => {
    const alarmType = getAlarmTypeByAlarm(alarm)
    if (alarmType) {
      const total = alarm.nrOfAlarms
      const real = 'nrOfRealAlarms' in alarm ? alarm.nrOfRealAlarms : total
      const filtered = total - real
      let classification = alarmType.classification.id

      // TODO: Maybe handle this better somehow...
      if (classification !== 'Alarm' && classification !== 'Warning') {
        console.log(
          'Alarm classification was',
          classification,
          'which is not directly supported in frontend, and therefore read as Alarm ' +
            '(since we need to choose between Alarm and Warning)'
        )
        classification = 'Alarm'
      }

      alarmTypeOccurrences[classification].total += total
      alarmTypeOccurrences[classification].real += real
      alarmTypeOccurrences[classification].filtered += filtered
    }
  })

  return alarmTypeOccurrences
}

/**
 * Find the timeStamp closest to the target.
 * An empty array will return undefined.
 *
 * @param arr - Array of objects with a timeStamp field to iterate.
 * @param target - Target time stamp.
 */
export const getClosestTimestamp = (arr: { timestamp: number }[], target: number) => {
  if (!arr.length) {
    return undefined
  }

  return arr.reduceRight(
    (bestTimestamp, o) => (Math.abs(o.timestamp - target) < Math.abs(bestTimestamp - target) ? o.timestamp : bestTimestamp),
    arr[arr.length - 1].timestamp
  )
}

/**
 * Checks whether two arrays of AssetWithAlarms have the same alarms.
 *
 * @param oldAssets - Array of AssetWithAlarms.
 * @param newAssets - Array of AssetWithAlarms.
 */
export const assetsWithAlarmsAreEqual = (oldAssets: AssetWithAlarms[], newAssets: AssetWithAlarms[]) => {
  if (oldAssets.length !== newAssets.length) {
    return false
  }

  return oldAssets.every(oldAsset =>
    newAssets.some(
      newAsset =>
        oldAsset.asset.baneDataId === newAsset.asset.baneDataId &&
        oldAsset.alarms.length === newAsset.alarms.length &&
        oldAsset.alarms.every(oldAlarm => newAsset.alarms.some(newAlarm => JSON.stringify(oldAlarm) === JSON.stringify(newAlarm)))
    )
  )
}

/**
 * Checks whether one alarm is a switch heat alarm with info about heat elements
 *
 * @param assetAlarm - Object of Alarm.
 */
export const isSwitchHeatAlarmWithHeatElements = (assetAlarm: Alarm) =>
  assetAlarm.internalAlarmSystem === InternalAlarmSystem.AggregatedSwitchHeatAlarm &&
  ALARM_TYPE_ID_WITH_HEAT_ELEMENTS.includes(assetAlarm.alarmTypeId)

export const getAlarms = (assetAlarms: Alarm[], asset: Asset, classification: AlarmClassification) =>
  assetAlarms.reduce<{ alarm: Alarm; alarmType: AlarmType }[]>((acc, alarm) => {
    const alarmType = getAlarmTypeByAlarm(alarm)

    if (alarmType?.classification.id === classification) {
      acc.push({
        alarm,
        alarmType,
      })
    }

    return acc
  }, [])
