import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import type { DatePeriod } from 'src/Types/DatePeriod'
import type { SwingEntry } from 'src/Types/SwingEntry'
import type { SwitchObject } from 'src/Types/SwitchObject'

import { useSwings } from 'src/Hooks/NetworkData/useSwings'
import { SwitchAlarmsAndSwing } from './SwitchAlarmsAndSwing'
import { HeaderWithExpandableInfo } from 'src/Components/HeaderWithExpandableInfo'
import { NoResult } from 'src/Components/NoResult'
import { SwitchInfoMinimal } from './SwingInfo/SwitchInfoMinimal'

type OwnProps = {
  assetWithAlarms: AssetWithAlarms
  datePeriod: DatePeriod
}

export const SwitchAlarmsAndSwings = ({ assetWithAlarms, datePeriod }: OwnProps) => {
  const { t } = useTranslation()

  const { asset } = assetWithAlarms

  const { machinesInSwitch, swings, swingStatistics, status, isLoading, isFetching, isSwingsGrouped } = useSwings({
    baneDataId: asset.baneDataId,
    datePeriod,
  })

  const machineBaneDataIdsInSwitch = machinesInSwitch?.map(machine => machine.baneDataId)

  const groupedSwings = useMemo<[string[], SwingEntry[]][]>(() => {
    if (isSwingsGrouped) {
      return machineBaneDataIdsInSwitch && swings ? [[machineBaneDataIdsInSwitch, swings]] : []
    }

    return (machineBaneDataIdsInSwitch || [])?.reduce((o: [string[], SwingEntry[]][], pointMachineBaneDataId) => {
      const filtered = (swings || []).filter(swing => swing.machineBaneDataIds.includes(pointMachineBaneDataId))
      o.push([[pointMachineBaneDataId], filtered])
      return o
    }, [])
  }, [isSwingsGrouped, machineBaneDataIdsInSwitch, swings])

  if (status === 'idle' || status === 'error') {
    return null
  }

  if (status === 'loading') {
    return (
      <Box mt={2}>
        <CircularProgress color="inherit" size={15} /> {t('switches.swings.loading')}
      </Box>
    )
  }

  const isLoaded = !!swings

  return (
    <>
      {!swings?.length && (
        <>
          <NoResult>{t('switches.swings.noneFound')}</NoResult>
          <SwitchInfoMinimal
            asset={asset as SwitchObject}
            swingStatistics={swingStatistics}
            machinesInSwitch={machinesInSwitch}
            isLoading={isLoading}
          />
        </>
      )}
      {!!swings?.length && (
        <HeaderWithExpandableInfo title={t('switches.statusSignal')} isDefaultExpanded>
          {groupedSwings.map(([machineBaneDataIds, swings]) => (
            <SwitchAlarmsAndSwing
              key={machineBaneDataIds.join('_')}
              machineBaneDataIds={machineBaneDataIds}
              swings={swings}
              isFetching={isFetching}
              isLoaded={isLoaded}
              asset={asset}
              swingStatistics={swingStatistics}
            />
          ))}
        </HeaderWithExpandableInfo>
      )}
    </>
  )
}
