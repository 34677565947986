import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { AlarmIcon } from 'src/Components/AlarmIcon'
import { NoAlarmIndicator } from 'src/Components/NoAlarmIndicator'
import type { SwingChartFilters } from 'src/Features/Switches/SwitchCard/SwingInfo/SwitchSwingChart/SwingChart'
import { SwingChart } from 'src/Features/Switches/SwitchCard/SwingInfo/SwitchSwingChart/SwingChart'
import { SwitchInfo } from './SwitchInfo'
import { Alert } from 'src/Components/Alert'
import { StyledContainer } from './elements'

import type { Asset } from 'src/Types/AssetTypes'
import type { SwingStatistics } from 'src/Types/SwingStatistics'
import type { SwingStatus } from 'src/Types/SwingEntry'
import type { Swing } from 'src/Types/SwingTypes'

import { usePointMachineAlarms } from 'src/Hooks/NetworkData/usePointMachineAlarms'
import { useSwingDetails } from 'src/Hooks/NetworkData/useSwingDetails'
import { findActiveStatusWindow } from 'src/Utils/statusWindow'
import { useBreakpointUp } from 'src/Hooks/useBreakpoint'
import { SwingInfoStatistics } from './SwingInfoStatistics'
import { SwingInfo } from './SwingInfo'
import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'

type OwnProps = {
  asset: Asset
  swingId: string
  swingStatus: SwingStatus
  swingStatistics: SwingStatistics[]
}

export const SwitchAndSwingInfo = ({ asset, swingId, swingStatus, swingStatistics }: OwnProps) => {
  const { t } = useTranslation()
  const isMediaMD = useBreakpointUp('md')
  const [swingData, setSwingData] = useState<Swing>()

  const [swingChartFilters, setSwingChartFilters] = useState<SwingChartFilters>(() => ({
    inactivePointMachines: [],
  }))

  const { swingData: tmpSwingData, status: swingDataQueryStatus } = useSwingDetails({ swingId })

  useEffect(() => {
    if (swingDataQueryStatus === 'success' || swingDataQueryStatus === 'error') {
      setSwingData(tmpSwingData)
    }
  }, [tmpSwingData, swingDataQueryStatus])

  const { alarms, status: alarmsQueryStatus } = usePointMachineAlarms(swingId)

  const isSwingLoading = swingDataQueryStatus === 'loading'
  const isAlarmsLoading = swingDataQueryStatus === 'loading'

  const filtersUpdated = useCallback(
    (updatedFilters: Partial<SwingChartFilters>) =>
      setSwingChartFilters(filters => ({
        ...filters,
        ...updatedFilters,
      })),
    []
  )

  if (isSwingLoading && !swingData) {
    return (
      <Box m={2}>
        <CircularProgress color="inherit" size={15} /> {t('switches.swings.details.loading')}
      </Box>
    )
  }

  if (!swingData || swingDataQueryStatus === 'error') {
    return null
  }

  const swingNames = swingData.machineSwings.map(s => s.baneDataNameParsed)

  const getStatusIcon = (status: SwingStatus) => {
    if (status === 'Alarm' || status === 'Warning') {
      return <AlarmIcon classification={status} size="small" />
    }

    if (alarmsQueryStatus === 'success') {
      if (status === 'Ok') {
        return <NoAlarmIndicator indicator="Ok" />
      }

      if (status === 'Pending') {
        return <NoAlarmIndicator indicator="Pending" />
      }
    }

    return null
  }

  const statusWindowAtSwingTime = findActiveStatusWindow(asset.assetStatusWindows, swingData.timestamp, [
    'Maintenance',
    'Out_of_Order',
  ])
  const wasInMaintenance = !!statusWindowAtSwingTime

  const currentStatistics =
    swingStatistics.filter(stat => swingData.machineSwings.some(swing => swing.baneDataId === stat.baneDataId))[0] ||
    swingStatistics[0]

  return (
    <StyledContainer>
      {wasInMaintenance && (
        <Box mb={2}>
          <Alert severity="error">
            {statusWindowAtSwingTime.status === 'Maintenance'
              ? t('switches.maintenanceWarning')
              : t('switches.outOfOrderWarning')}
          </Alert>
        </Box>
      )}
      <SubHeaderWithExpandableInfo
        title={
          <>
            {t('switches.chart.title')}
            {isMediaMD && <> ({swingNames.join(',')})</>}
            {getStatusIcon(swingStatus)}
          </>
        }
        isLoading={swingDataQueryStatus === 'loading'}
        isDefaultExpanded
        hideText={t('switches.chart.title')}
        subHeaderKey={SubHeaderKey.SwingChart}
      >
        <SwingChart swing={swingData} filters={swingChartFilters} onFiltersUpdated={filtersUpdated} asset={asset} />
      </SubHeaderWithExpandableInfo>
      <SwingInfo asset={asset} swing={swingData} alarms={alarms} isLoading={isSwingLoading || isAlarmsLoading} />
      <SwitchInfo machineSwings={swingData.machineSwings} isLoading={isSwingLoading} isCurrent={swingData.isCurrent} />
      {!!currentStatistics && (
        <SwingInfoStatistics baneDataId={asset.baneDataId} swingStatistics={currentStatistics} isLoading={isSwingLoading} />
      )}
    </StyledContainer>
  )
}
