import { useMemo } from 'react'
import { styled } from '@mui/material/styles'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { ExpandableAlarmSensorData } from 'src/Features/TrackCircuits/TrackCircuitCard/ExpandableAlarmSensorData'

import type { Alarm } from 'src/Types/Alarm'
import type { AssetType, AssetStatusWindowStatus } from 'src/Types/AssetTypes'
import { InternalAlarmSystem } from 'src/Types/AlarmSystem'

import { formatLocalDateTime } from 'src/Utils/format'
import { getAlarmStart, getAlarmEnd, isSwitchHeatAlarmWithHeatElements } from 'src/Utils/alarms'

import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { useAlarmTypeByAlarm } from 'src/Hooks/useAlarmTypeByAlarm'
import { useLanguage } from 'src/Hooks/useLanguage'
import themeColors from 'src/theme'
import Box from '@mui/material/Box'

const StyledDescriptionLink = styled('a')`
  font-size: 14px;
  color: ${themeColors.linkText};
`
const StyledBox = styled(Box)`
  width: 100%;
  display: table;
  table-layout: fixed;
`

type OwnProps = {
  alarm: Alarm
  assetType: AssetType
  showAlarmsInMaintenance: boolean
  showExtraAlarmsInfo: boolean
  windowStatus?: AssetStatusWindowStatus | 'Both'
  expandAlarmChart?: boolean
}

export const AlarmEntry = ({
  alarm,
  assetType,
  showAlarmsInMaintenance,
  showExtraAlarmsInfo,
  windowStatus,
  expandAlarmChart = false,
}: OwnProps) => {
  const { t, currentLanguage } = useLanguage()
  const isMediaSM = useBreakpointDown('sm')

  const alarmType = useAlarmTypeByAlarm(alarm)
  const descriptionLength = isMediaSM ? 'short' : 'full'
  const alarmDescription = alarmType
    ? alarmType.type.localization[descriptionLength][currentLanguage]
    : t('alarms.unknownType', { alarmTypeId: alarm.alarmTypeId })

  let realAlarmOccurrences = alarm.nrOfAlarms
  let alarmsInMaintenance = 0

  if ('nrOfRealAlarms' in alarm) {
    realAlarmOccurrences = alarm.nrOfRealAlarms
    alarmsInMaintenance = alarm.nrOfAlarms - realAlarmOccurrences
  }

  const cellEntry = (value: string | number, title?: string) => <div title={title}>{value}</div>

  const alarmStart = getAlarmStart(alarm)
  const alarmEnd = getAlarmEnd(alarm)

  const isSwitchAlarm =
    alarm.internalAlarmSystem === InternalAlarmSystem.AggregatedPointMachineAlarm ||
    alarm.internalAlarmSystem === InternalAlarmSystem.AggregatedMachineHeatAlarm

  let maintenanceLabelKey = 'alarms.alarmEntry.occurrencesWhileOutOfOrder'
  if (windowStatus === 'Maintenance') {
    maintenanceLabelKey = 'alarms.alarmEntry.occurrencesWhileInMaintenance'
  } else if (windowStatus === 'Both') {
    maintenanceLabelKey = 'alarms.alarmEntry.occurrencesWhileOutOfOrderOrInMaintenance'
  }

  const alarmDetails = (
    <TableRow>
      <TableCell padding="checkbox">
        {cellEntry(alarmDescription, t('alarms.alarmEntry.alarmCode', { alarmTypeId: alarm.alarmTypeId }))}
      </TableCell>
      <TableCell padding="checkbox">
        {showAlarmsInMaintenance && alarmsInMaintenance > 0
          ? cellEntry(`${realAlarmOccurrences} (${alarmsInMaintenance})`, t(maintenanceLabelKey))
          : cellEntry(realAlarmOccurrences)}
      </TableCell>
      {isMediaSM ? (
        <TableCell padding="checkbox">
          {alarmStart && cellEntry(`${formatLocalDateTime(alarmStart)} -`)}
          {alarmEnd && cellEntry(formatLocalDateTime(alarmEnd))}
        </TableCell>
      ) : (
        <>
          <TableCell padding="checkbox">{alarmStart && cellEntry(formatLocalDateTime(alarmStart))}</TableCell>
          <TableCell padding="checkbox">{alarmEnd && cellEntry(formatLocalDateTime(alarmEnd))}</TableCell>
        </>
      )}
      {showExtraAlarmsInfo && (
        <TableCell padding="checkbox">
          {isSwitchAlarm && alarm.baneDataName && cellEntry(alarm.baneDataName)}
          {alarm.internalAlarmSystem === InternalAlarmSystem.AggregatedSwitchHeatAlarm &&
            isSwitchHeatAlarmWithHeatElements(alarm) && (
              <>
                {alarm.switchName && cellEntry(alarm.switchName)}
                {!alarm.switchName && alarm.switchHeatElementName && cellEntry(alarm.switchHeatElementName)}
                {alarm.additionalInfo && cellEntry(alarm.additionalInfo)}
              </>
            )}
        </TableCell>
      )}
      <TableCell padding="checkbox" title={t('alarms.alarmDescriptionsUrl.long')}>
        <StyledDescriptionLink href={alarmType?.type?.description_URL} target="_blank" rel="noopener noreferrer">
          <OpenInNewIcon fontSize="small" />
        </StyledDescriptionLink>
      </TableCell>
    </TableRow>
  )

  const datePeriod = useMemo(
    () => ({
      fromDate: alarmStart,
      toDate: alarmEnd,
    }),
    [alarmStart, alarmEnd]
  )

  return (
    <>
      {alarmDetails}
      {alarm.internalAlarmSystem === InternalAlarmSystem.AggregatedTrackCircuitAlarm && (
        <TableRow>
          <TableCell padding="none" colSpan={isMediaSM ? 4 : 5}>
            <StyledBox>
              <ExpandableAlarmSensorData alarm={alarm} datePeriod={datePeriod} expandReturnCurrentChart={expandAlarmChart} />
            </StyledBox>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
