import { useQueryWithErrorHandling } from 'src/Hooks/NetworkData/useQueryWithErrorHandling'
import {
  getAssetStatusOutOfOrder,
  postAssetStatusOutOfOrderEnter,
  postAssetStatusOutOfOrderExit,
} from 'src/Providers/AssetStatus'
import { useMutationWithErrorHandling } from 'src/Hooks/NetworkData/useMutationWithErrorHandling'

export type AssetStatusEntriesParams = Parameters<typeof getAssetStatusOutOfOrder>[0]

export const useAssetStatusEntries = (params: AssetStatusEntriesParams) => {
  const query = useQueryWithErrorHandling(
    ['assetStatusEntries', params],
    () => getAssetStatusOutOfOrder(params),
    {
      staleTime: 5 * 60 * 1000,
    },
    'manualAssetStatus.fetchError'
  )

  return {
    ...query,
    assetStatusEntries: query.data,
  }
}

export const useAssetStatusOutOfOrderEnter = () =>
  useMutationWithErrorHandling<void, Parameters<typeof postAssetStatusOutOfOrderEnter>[0]>(
    postAssetStatusOutOfOrderEnter,
    {},
    'manualAssetStatus.mutationError',
    'manualAssetStatus.mutationSuccess'
  )

export const useAssetStatusOutOfOrderExit = () =>
  useMutationWithErrorHandling<void, Parameters<typeof postAssetStatusOutOfOrderExit>[0]>(
    postAssetStatusOutOfOrderExit,
    {},
    'manualAssetStatus.mutationError',
    'manualAssetStatus.mutationSuccess'
  )
