import { MenuItem } from 'src/Components/UIComponents'
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined'
import type { MouseEvent } from 'react'
import { useLanguage } from 'src/Hooks/useLanguage'

type OwnProps = {
  onClick?: (event: MouseEvent) => void
}

export const ManualAssetStatusMenuItemButton = ({ onClick }: OwnProps) => {
  const { t } = useLanguage()

  return (
    <MenuItem onClick={onClick}>
      <DoNotDisturbAltOutlinedIcon />
      {t('manualAssetStatus.cardButton')}
    </MenuItem>
  )
}
