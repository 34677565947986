import type { Filter } from 'src/Types/Filter'
import { FilterType } from 'src/Types/Filter'
import type { Asset, AssetStatusWindow, AssetStatusWindowStatus } from 'src/Types/AssetTypes'
import type { SystemStatus } from 'src/Types/SystemStatus'

const assetStatusWindowMatch = (filter: Filter, endOfSearchPeriod?: number, assetStatusWindows?: AssetStatusWindow[]) => {
  if (!endOfSearchPeriod) {
    return false
  }

  const possibleAssetStatusWindowsStatus = ['Out_of_Order'] as AssetStatusWindowStatus[]
  for (let i = 0; i < possibleAssetStatusWindowsStatus.length; i++) {
    const status = possibleAssetStatusWindowsStatus[i]
    if (
      filter.values.includes(status) &&
      assetStatusWindows?.some(w => w.status === status && w.startTime < endOfSearchPeriod && w.endTime > endOfSearchPeriod)
    ) {
      // Has 'active' period relative to endOfSearchPeriod then
      return true
    }
  }
  return false
}

/**
 * Whether there is a match by SystemStatus (reflected by the main icon)
 * @param filter
 * @param statusWithAssetStatusWindows
 */
const systemStatusMatch = (filter: Filter, statusWithAssetStatusWindows: SystemStatus) =>
  filter.values.includes(statusWithAssetStatusWindows)

/**
 * Alarm-disregarding asset-level filtering, used in combination with
 * alarm-filtering (you have alarm-level filtering in filterAlarms.ts)
 * (Both filterAlarms and filterAssets are used by filterAssetsWithAlarm)
 * @param filters The filters
 * @param asset The asset
 * @param endOfSearchPeriod The date range end (used for out of order periods filtering)
 */
export const filterAsset = (filters: Filter[], asset: Asset, endOfSearchPeriod?: number) =>
  filters.every(filter => {
    switch (filter.type) {
      case FilterType.Status: {
        return (
          systemStatusMatch(filter, asset.statusWithAssetStatusWindows) ||
          assetStatusWindowMatch(filter, endOfSearchPeriod, asset.assetStatusWindows)
        )
      }
      case FilterType.AssetId: {
        return filter.values.includes(`${asset.baneDataId}-${asset.assetType}`)
      }
      case FilterType.System: {
        return filter.values.includes(asset.assetType)
      }
      default:
        return true
    }
  })
