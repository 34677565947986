import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { eachDayOfInterval } from 'date-fns/eachDayOfInterval'
import { isSameDay } from 'date-fns/isSameDay'
import { makeStyles } from 'tss-react/mui'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import ListSubheader from '@mui/material/ListSubheader'
import MenuItem from '@mui/material/MenuItem'
import type { SelectChangeEvent } from '@mui/material/Select'
import Select from '@mui/material/Select'

import { ArrowButtonWithText } from 'src/Components/ArrowButtonWithText'
import { SwingDirectionIcon } from './SwingDirectionIcon'

import type { SwingEntry } from 'src/Types/SwingEntry'
import type { AssetStatusWindow } from 'src/Types/AssetTypes'

import { formatLocalDateTime } from 'src/Utils/format'
import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { findActiveStatusWindow } from 'src/Utils/statusWindow'
import themeColors from 'src/theme'

const stripedBackground = (mainColor: string, stripeColor: string) => {
  return `repeating-linear-gradient(
    -55deg,
    ${mainColor},
    ${mainColor} 27px,
    ${stripeColor} 15px,
    ${stripeColor} 30px
  )`
}

const useStyles = makeStyles()(theme => ({
  swingArrows: {
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  currentSwingDate: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
  dropDownFormControl: {
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  listHeader: {
    backgroundColor: '#fff',
    color: themeColors.secondary,
  },
  warningEntry: {
    backgroundColor: `${themeColors.warningBackground} !important`,
  },
  alarmEntry: {
    backgroundColor: `${themeColors.errorBackground} !important`,
  },
  outOfOrderEntry: {
    backgroundColor: `${themeColors.outOfOrder} !important`,
  },
  outOfOrderEntryAlarm: {
    background: stripedBackground(themeColors.outOfOrder, themeColors.error),
  },
  outOfOrderEntryWarning: {
    background: stripedBackground(themeColors.outOfOrder, themeColors.warning),
  },
  maintenanceEntry: {
    backgroundColor: `${themeColors.maintenance} !important`,
  },
  maintenanceEntryAlarm: {
    background: stripedBackground(themeColors.maintenance, themeColors.error),
  },
  maintenanceEntryWarning: {
    background: stripedBackground(themeColors.maintenance, themeColors.warning),
  },
  currentEntry: {
    fontWeight: 'bold',
  },
  swingDirectionIcon: {
    verticalAlign: 'bottom',
    marginRight: 4,
  },
  swingDate: {
    flex: 1,
    textAlign: 'left',
  },
  swingDateLight: {
    flex: 1,
    textAlign: 'left',
    color: `${themeColors.maintenanceText} !important`,
  },
  swingDirectionText: {
    marginRight: 4,
  },
  swingDirectionTextMaintenance: {
    color: themeColors.disabledLight,
  },
}))

type StyleKeys = keyof ReturnType<typeof useStyles>['classes']

const getSwingElementClass = (status: SwingEntry['status'], realStatus?: SwingEntry['status']): StyleKeys | undefined => {
  switch (status) {
    case 'Alarm':
      return 'alarmEntry'
    case 'Warning':
      return 'warningEntry'
    case 'Out_of_Order': {
      return realStatus && (realStatus === 'Alarm' || realStatus === 'Warning')
        ? `outOfOrderEntry${realStatus}`
        : 'outOfOrderEntry'
    }
    case 'Maintenance':
      return realStatus && (realStatus === 'Alarm' || realStatus === 'Warning')
        ? `maintenanceEntry${realStatus}`
        : 'maintenanceEntry'
    default:
      return undefined
  }
}

type OwnProps = {
  swings: SwingEntry[]
  selectedSwingTimestamp: number
  isRefetching: boolean
  statusWindows?: AssetStatusWindow[]
  onSwingChanged: (swingId: number) => void
}

// This fetches the three relevant swings (previous, current, next), but needs to guard
// against a negative index in the slice by prepending an undefined element.
const getRelevantSwings = (swings: SwingEntry[], currentIndex: number) =>
  [undefined, ...swings].slice(currentIndex, currentIndex + 3)

export const SwingSelector = ({ swings, selectedSwingTimestamp, isRefetching, statusWindows, onSwingChanged }: OwnProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const narrowDisplay = useBreakpointDown('md')
  const isMediaLG = useBreakpointDown('lg')

  const swingsWithStatus = useMemo(
    () =>
      swings.map<SwingEntry>(s => {
        const activeWindow = findActiveStatusWindow(statusWindows, s.timestamp)
        const status = activeWindow ? activeWindow.status : s.status

        return {
          ...s,
          status,
          realStatus: s.status,
        }
      }),
    [statusWindows, swings]
  )

  const groupedSwings = useMemo(
    () =>
      swingsWithStatus.length
        ? eachDayOfInterval({
            start: swingsWithStatus[0].timestamp,
            end: swingsWithStatus[swingsWithStatus.length - 1].timestamp,
          }).map(date => {
            const daySwings = swingsWithStatus.filter(s => isSameDay(s.timestamp, date))

            return {
              date,
              swings: daySwings,
            }
          })
        : [],
    [swingsWithStatus]
  )

  const arrowText = (translationKey: string, swing?: SwingEntry) => {
    if (!swing) {
      return t(translationKey)
    }

    return !narrowDisplay ? `${t(translationKey)} ${formatLocalDateTime(swing.timestamp)}` : ''
  }

  const swingChanged = (event: SelectChangeEvent<string>) => {
    const swing = swingsWithStatus.find(swing => swing.swingId === event.target.value)
    if (swing) {
      onSwingChanged(swing.timestamp)
    }
  }

  const selectedIndex = swings.findIndex(swing => swing.timestamp === selectedSwingTimestamp)
  const [prevSwing, currentSwing, nextSwing] = getRelevantSwings(swingsWithStatus, selectedIndex)

  if (!currentSwing) {
    return null
  }

  const selectClassKey = getSwingElementClass(currentSwing.status, currentSwing.realStatus)
  const selectClass = selectClassKey ? classes[selectClassKey] : undefined

  return (
    <Grid container className={classes.swingArrows}>
      <Grid item xs={narrowDisplay ? 2 : 4}>
        {prevSwing && (
          <ArrowButtonWithText
            text={arrowText(isMediaLG ? 'switches.prevSwing_short' : 'switches.prevSwing', prevSwing)}
            direction="left"
            onClick={() => onSwingChanged(prevSwing.timestamp)}
          />
        )}
      </Grid>
      <Grid item xs={narrowDisplay ? 8 : 4} className={classes.currentSwingDate}>
        <FormControl className={classes.dropDownFormControl}>
          <InputLabel id="swing-selector-label">{t('switches.statusLabels.time')}</InputLabel>
          <Select
            labelId="swing-selector-label"
            id="swing-selector"
            value={currentSwing?.swingId}
            onChange={swingChanged}
            className={selectClass}
          >
            {groupedSwings.map(day => [
              <ListSubheader className={classes.listHeader} color="primary">
                {formatLocalDateTime(day.date, true)}
              </ListSubheader>,
              day.swings.map(swing => {
                const direction = swing.direction
                const directionLabel = t(`switches.statusLabels.direction.${direction}`)
                const itemClassKey = getSwingElementClass(swing.status, swing.realStatus)
                const itemClass = itemClassKey ? classes[itemClassKey] : undefined

                const isMaintenanceSwing = swing.status === 'Maintenance'
                const isNonAlarmSwing = swing.status !== 'Alarm'
                const dateClass = isMaintenanceSwing ? classes.swingDateLight : classes.swingDate
                const iconAlarmOrMaintenanceColor = isMaintenanceSwing ? themeColors.disabledLight : themeColors.disabledText

                return (
                  <MenuItem
                    key={swing.swingId}
                    value={swing.swingId}
                    className={clsx(itemClass, { [classes.currentEntry]: swing.timestamp === selectedSwingTimestamp })}
                    style={{ display: 'flex' }}
                  >
                    <span className={dateClass}>{formatLocalDateTime(swing.timestamp)}</span>
                    {!narrowDisplay && (
                      <span
                        className={clsx(classes.swingDirectionText, {
                          [classes.swingDirectionTextMaintenance]: isMaintenanceSwing,
                        })}
                      >
                        {directionLabel}
                      </span>
                    )}
                    <SwingDirectionIcon
                      direction={direction}
                      className={classes.swingDirectionIcon}
                      color={isNonAlarmSwing || isMaintenanceSwing ? iconAlarmOrMaintenanceColor : undefined}
                      title={directionLabel}
                    />
                  </MenuItem>
                )
              }),
            ])}
          </Select>
        </FormControl>
      </Grid>
      <Grid container item xs={narrowDisplay ? 2 : 4} alignContent="flex-end" flexDirection="column">
        <ArrowButtonWithText
          text={arrowText(isMediaLG ? 'switches.nextSwing_short' : 'switches.nextSwing', nextSwing)}
          direction="right"
          disabled={!nextSwing}
          isRefetching={isRefetching}
          onClick={() => {
            if (nextSwing) {
              onSwingChanged(nextSwing.timestamp)
            }
          }}
        />
      </Grid>
    </Grid>
  )
}
