import { useState, useEffect, Fragment } from 'react'
import { ExpandableAlarmCard } from './ExpandableAlarmCard'
import { SwitchAlarmsCard } from './SwitchAlarmsCard'
import { DetailedAlarms } from './DetailedAlarms'
import { LazyLoadedAssets } from './LazyLoadedAssets'
import { TrackCircuitCardExpandedContent } from 'src/Features/TrackCircuits/TrackCircuitCard/TrackCircuitCardExpandedContent'
import { BenderCardExpandedContent } from 'src/Features/SignalEarthFaults/BenderCard/BenderCardExpandedContent'
import { RackCardExpandedContent } from 'src/Features/SignalEarthFaults/RackCard/RackCardExpandedContent'

import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import { AssetType } from 'src/Types/AssetTypes'
import type { DatePeriod } from 'src/Types/DatePeriod'
import { ErrorBoundary } from 'src/Components/ErrorBoundary'
import { concatDistinct } from 'src/Utils/array'
import { getCardIdByAsset } from 'src/Utils/cardId'
import { SwitchHeatCardExpandedContentWrap } from 'src/Features/SwitchHeat/SwitchHeatCardExpandedContentWrap'
import { useCardIdFromUrl } from 'src/Hooks/useCardIdFromUrl'
import { useFeatureOverride } from 'src/Hooks/useFeatureOverride'
import { SessionFeatures } from 'src/Hooks/useSessionFeature'

type OwnProps = {
  assetsWithAlarms: AssetWithAlarms[]
  datePeriod: DatePeriod
  showAlarmsInMaintenance?: boolean
  forceExpandCardIds?: string[]
  forceCollapseCardIds?: string[]
  onExpandedCardIdsChanged?: (expandedIds: string[], userTriggered: boolean) => void
}

const cardForAsset = (asset: AssetWithAlarms, datePeriod: DatePeriod, showAlarmsInMaintenance: boolean) => {
  switch (asset.asset.assetType) {
    case AssetType.TrackCircuit:
      return (
        <DetailedAlarms assetWithAlarms={asset} showAlarmsInMaintenance={showAlarmsInMaintenance}>
          <TrackCircuitCardExpandedContent assetWithAlarms={asset} datePeriod={datePeriod} />
        </DetailedAlarms>
      )
    case AssetType.SwitchPointMachine:
    case AssetType.SwitchMachineHeat:
    case AssetType.DerailerMachine:
      return (
        <SwitchAlarmsCard assetWithAlarms={asset} datePeriod={datePeriod} showAlarmsInMaintenance={showAlarmsInMaintenance} />
      )
    case AssetType.SwitchHeat:
      return (
        <DetailedAlarms assetWithAlarms={asset} showAlarmsInMaintenance={showAlarmsInMaintenance}>
          <SwitchHeatCardExpandedContentWrap assetWithAlarms={asset} datePeriod={datePeriod} />
        </DetailedAlarms>
      )
    case AssetType.Bender:
      return (
        <DetailedAlarms assetWithAlarms={asset} showAlarmsInMaintenance={showAlarmsInMaintenance}>
          <BenderCardExpandedContent assetWithAlarms={asset} datePeriod={datePeriod} />
        </DetailedAlarms>
      )
    case AssetType.Rack:
      return (
        <DetailedAlarms assetWithAlarms={asset} showAlarmsInMaintenance={showAlarmsInMaintenance}>
          <RackCardExpandedContent assetWithAlarms={asset} datePeriod={datePeriod} />
        </DetailedAlarms>
      )
    default:
      return null
  }
}

const sortStrings = (strings: string[]) => strings.sort((a, b) => a.localeCompare(b))

export const AssetsWithAlarms = ({
  assetsWithAlarms,
  datePeriod,
  showAlarmsInMaintenance = false,
  forceExpandCardIds = [],
  forceCollapseCardIds = [],
  onExpandedCardIdsChanged,
}: OwnProps) => {
  const [expandedCardIds, setExpandedCardIds] = useState<string[]>([])
  const lazyLoadDisabled = useFeatureOverride(SessionFeatures.DisableLazyLoad)

  const updateExpandedIds = (updatedCardIds: string[], userTriggered: boolean = false) => {
    if (sortStrings(expandedCardIds).join(',') === sortStrings(updatedCardIds).join(',')) {
      return
    }
    setExpandedCardIds(updatedCardIds)
    onExpandedCardIdsChanged?.(updatedCardIds, userTriggered)
  }

  useEffect(() => {
    updateExpandedIds(concatDistinct(expandedCardIds, forceExpandCardIds).filter(id => !forceCollapseCardIds.includes(id)))
  }, [forceExpandCardIds, forceCollapseCardIds]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleExpandedStateChange = (cardId: string, expanded: boolean) => {
    const hasId = expandedCardIds.includes(cardId)
    if (expanded && !hasId) {
      updateExpandedIds([...expandedCardIds, cardId], true)
    }
    if (!expanded && hasId) {
      updateExpandedIds(
        expandedCardIds.filter(id => id !== cardId),
        true
      )
    }
  }

  const cardIdFromUrl = useCardIdFromUrl()
  if (cardIdFromUrl) {
    assetsWithAlarms = assetsWithAlarms.filter(assetWithAlarms => getCardIdByAsset(assetWithAlarms.asset) === cardIdFromUrl)
  }

  const renderItem = (assetWithAlarms: AssetWithAlarms) => {
    const { asset } = assetWithAlarms
    const cardId = getCardIdByAsset(asset)
    let forceSetToggle: boolean | undefined
    if (forceExpandCardIds.includes(cardId)) {
      forceSetToggle = true
    }
    if (forceCollapseCardIds.includes(cardId)) {
      forceSetToggle = false
    }

    return (
      <ErrorBoundary>
        <ExpandableAlarmCard
          key={cardId}
          assetWithAlarms={assetWithAlarms}
          expandedContent={cardForAsset(assetWithAlarms, datePeriod, showAlarmsInMaintenance)}
          onExpandedStateChange={handleExpandedStateChange}
          forceToggleExpanded={forceSetToggle}
          defaultExpanded={!!cardIdFromUrl}
        />
      </ErrorBoundary>
    )
  }

  if (lazyLoadDisabled) {
    return assetsWithAlarms.map(assetWithAlarms => (
      <Fragment key={getCardIdByAsset(assetWithAlarms.asset)}>{renderItem(assetWithAlarms)}</Fragment>
    ))
  }

  return <LazyLoadedAssets assetsWithAlarms={assetsWithAlarms} expandedCardIds={expandedCardIds} renderItem={renderItem} />
}
